import React from "react";
import { Navigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { urlToPageMap } from "../../shared/Utils";
import Protected from "./ProtectedRoutes";
import RetailView from "../retail/Retail";
import RetailCertificate from "../retail/certificate/Certificate";
import RetailCustomer from "../retail/customer/Customer";
import CustomerDetails from "../retail/customer/CustomerDetails";
import CustomerForm from "../retail/customer/CustomerForm";
import SearchComponent from "../retail/search/SearchComponent";
import SearchGrid from "../retail/search/SearchGrid";
import RetailNavBar from "../retail/retailNavBar/RetailNavBar";
import featureFlag from "../../featureToggler/featureFlag";
import { ecmRole } from "./Subscription";

export function retailRoutes(setHideHeader, setHideNav) {
    return {
        id: uuidv4(),
        path: "/retail",
        element: (
            <Protected
                allowedSubscriptions={featureFlag.retail.index.subscription}
                allowedRETRoles={featureFlag.retail.index.ecmRole}
                allowedCEMRoles={[
                    ecmRole.CEMAdmin,
                    ecmRole.CEMManager,
                    ecmRole.CEMCertificateReviewer,
                    ecmRole.CEMViewer,
                ]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <RetailView setHideNav={setHideNav} />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: true,
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.retail.index.subscription}
                        allowedRETRoles={featureFlag.retail.index.ecmRole}
                        allowedCEMRoles={[
                            ecmRole.CEMAdmin,
                            ecmRole.CEMManager,
                            ecmRole.CEMCertificateReviewer,
                            ecmRole.CEMViewer,
                        ]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <RetailNavBar isLogoVisible />
                        <SearchComponent setHideNav={setHideNav} />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: urlToPageMap.retailCustomerAndCertificates,
                exact: true,
                element: (
                    <Protected
                        allowedSubscriptions={
                            featureFlag.retail.editCustomerAndCertificate.subscription
                        }
                        allowedRETRoles={[
                            ...featureFlag.retail.viewCustomerAndCertificate.ecmRole,
                            ...featureFlag.retail.editCustomerAndCertificate.ecmRole,
                        ]}
                        allowedCEMRoles={[
                            ecmRole.CEMAdmin,
                            ecmRole.CEMManager,
                            ecmRole.CEMCertificateReviewer,
                            ecmRole.CEMViewer,
                        ]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <RetailNavBar isLogoVisible={false} />
                        <CustomerDetails setHideNav={setHideNav} />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: urlToPageMap.retailCustomer,
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.retail.editCustomer.subscription}
                        allowedRETRoles={[
                            ...featureFlag.retail.editCustomer.ecmRole,
                            ...featureFlag.retail.viewCustomer.ecmRole,
                        ]}
                        allowedCEMRoles={[
                            ecmRole.CEMAdmin,
                            ecmRole.CEMManager,
                            ecmRole.CEMCertificateReviewer,
                            ecmRole.CEMViewer,
                        ]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <RetailNavBar isLogoVisible={false} />
                        <RetailCustomer setHideNav={setHideNav} />
                    </Protected>
                ),
                children: [
                    {
                        id: uuidv4(),
                        index: true,
                        exact: true,
                        element: (
                            <Protected
                                allowedSubscriptions={featureFlag.retail.editCustomer.subscription}
                                allowedRETRoles={[
                                    ...featureFlag.retail.editCustomer.ecmRole,
                                    ...featureFlag.retail.viewCustomer.ecmRole,
                                ]}
                                allowedCEMRoles={[
                                    ecmRole.CEMAdmin,
                                    ecmRole.CEMManager,
                                    ecmRole.CEMCertificateReviewer,
                                    ecmRole.CEMViewer,
                                ]}
                                setHideHeader={setHideHeader}
                                setHideNav={setHideNav}>
                                <CustomerForm setHideNav={setHideNav} />
                            </Protected>
                        ),
                    },
                    {
                        id: uuidv4(),
                        path: urlToPageMap.retailAddCertificate,
                        exact: true,
                        element: (
                            <Protected
                                allowedSubscriptions={featureFlag.retail.editCustomer.subscription}
                                allowedRETRoles={[
                                    ...featureFlag.retail.editCustomer.ecmRole,
                                    ...featureFlag.retail.viewCustomer.ecmRole,
                                ]}
                                allowedCEMRoles={[
                                    ecmRole.CEMAdmin,
                                    ecmRole.CEMManager,
                                    ecmRole.CEMCertificateReviewer,
                                    ecmRole.CEMViewer,
                                ]}
                                setHideHeader={setHideHeader}
                                setHideNav={setHideNav}>
                                <RetailCertificate setHideNav={setHideNav} />
                            </Protected>
                        ),
                    },
                ],
            },
            {
                id: uuidv4(),
                path: urlToPageMap.retailSearch,
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.retail.search.subscription}
                        allowedRETRoles={featureFlag.retail.search.ecmRole}
                        allowedCEMRoles={[
                            ecmRole.CEMAdmin,
                            ecmRole.CEMManager,
                            ecmRole.CEMCertificateReviewer,
                            ecmRole.CEMViewer,
                        ]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <RetailNavBar isLogoVisible />
                        <SearchGrid setHideNav={setHideNav} />
                    </Protected>
                ),
            },
        ],
    };
}

function routes(setHideHeader, setHideNav) {
    return [
        retailRoutes(setHideHeader, setHideNav),
        {
            id: uuidv4(),
            path: "/",
            element: <Navigate to="/retail" />,
        },
        {
            id: uuidv4(),
            path: "*",
            element: <Protected setHideHeader={setHideHeader} setHideNav={setHideNav} />,
        },
    ];
}

export default routes;
