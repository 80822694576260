import React, { useCallback, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { SLoader, SPagination, SIcon } from "@avalara/skylab-react";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import {
    fetchCertificateRequestsAPI,
    selectPage,
    selectSortColumn,
    setSortColumn,
    selectLoading,
} from "../../../app/certificateRequestSlice";
import { fetchCoverLetterByIdAPI } from "../../../app/coverLetterSlice";
import { selectSession, updateRowCountPreference } from "../../../app/sessionSlice";
import { getRowsPerPage } from "../../../shared/Utils";
import CoverLetterPreviewDialog from "../CoverLetterPreviewDialog";
import RecordCount from "../../search/RecordCount";

function SingleRequestGrid() {
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const [submitting, setSubmitting] = useState(false);
    const loading = useSelector(selectLoading);
    const page = useSelector(selectPage);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const [coverLetterId, setCoverLetterId] = useState(null);
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

    function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        dispatch(
            fetchCertificateRequestsAPI(null, null, session.rowsPerPage, e.detail.startIndex, false)
        );
        setSubmitting(false);
    }

    const formatRequesterEmail = request => {
        if (request.communications?.length > 0) {
            const lastCommunication = request.communications[request.communications.length - 1];
            return (
                <a
                    href={`mailto: ${lastCommunication?.emailAddress}`}
                    aria-label="{lastCommunication.emailAddress}"
                    aria-hidden={lastCommunication?.emailAddress ? "false" : "true"}>
                    {lastCommunication?.emailAddress}
                </a>
            );
        }
        return null;
    };

    const formatResponded = request => {
        return request.status === "F" ? <SIcon name="check" aria-hidden="true" /> : "";
    };

    const getCoverLetterPreview = request => {
        const lastCommunication = request.communications[request.communications.length - 1];
        setCoverLetterId(lastCommunication.coverLetterId);
        dispatch(fetchCoverLetterByIdAPI(lastCommunication.coverLetterId));
        setIsPreviewDialogOpen(true);
    };

    const columns = [
        gridHeader(
            "Customer code",
            "customerId",
            (value, row) => <Link to={`/customer/${value}`}>{row.customer.customerNumber}</Link>,
            null,
            "",
            null,
            null
        ),
        gridHeader(
            "Customer name",
            "customer",
            (value, row) => row.customer.name,
            null,
            "",
            null,
            null
        ),
        gridHeader(
            "Request date",
            "created",
            value => new Date(value).toLocaleDateString(),
            null,
            "",
            null,
            null,
            sortFunction,
            "created"
        ),
        gridHeader(
            "Requested by",
            "communications",
            (value, row) => formatRequesterEmail(row),
            null,
            "",
            null,
            null
        ),
        gridHeader(
            "Request Letter",
            "communications",
            (value, row) => (
                <button className="link font-bold" onClick={() => getCoverLetterPreview(row)}>
                    <SIcon name="zoom-in" className="blue-icon" aria-label="zoom-in" />
                </button>
            ),
            null,
            "",
            null,
            null
        ),
        gridHeader(
            "Responded",
            "status",
            (value, row) => formatResponded(row),
            null,
            "",
            null,
            null
        ),
    ];

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            await dispatch(
                fetchCertificateRequestsAPI(
                    null,
                    null,
                    e.detail.rowsPerPage,
                    e.detail.startIndex,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };
    const getRowKey = useCallback(row => {
        return row.id;
    }, []);

    const table =
        submitting || loading ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <s-table-container>
                <DataGrid
                    columns={columns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    sortColumn={sortColumn}
                    inContainer
                />
            </s-table-container>
        );

    return (
        <React.Fragment>
            {paginateData.totalRecords > 0 ? (
                <div className="margin-top-sm">
                    <RecordCount
                        selectedCount={0}
                        recordCount={new Intl.NumberFormat().format(paginateData.totalRecords)}
                        recordName="requests"
                    />
                </div>
            ) : null}
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}
            {isPreviewDialogOpen ? (
                <CoverLetterPreviewDialog
                    setIsPreviewDialogOpen={setIsPreviewDialogOpen}
                    isPreviewDialogOpen={isPreviewDialogOpen}
                    coverLetterId={coverLetterId}
                />
            ) : null}
        </React.Fragment>
    );
}
export default SingleRequestGrid;
