import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SRow, SCol, SIcon, SDatepicker, SSelect } from "@avalara/skylab-react";

import SelectCertificateStatus from "../../sharedComponents/SelectCertificateStatus";
import SelectOutgoingExemptionReasons from "../../sharedComponents/SelectOutgoingExemptReasons";
import SelectOutgoingExposureZone from "../../sharedComponents/SelectOutgoingExposureZone";
import { errorTag, noErrorTag } from "../../../shared/Utils";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import CertificateCustomField from "./CertificateCustomFieldFilter";

const FilterByVendorCertificate = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const {
        isLoading,
        updateFilters,
        handleCheckBoxChange,
        handleMultilineInputChange,
        filterState,
        setFilterState,
    } = props;

    const {
        isCertificateDetails,
        certificateStatus,
        certificateRegions,
        exemptReasons,
        isPurchaseOrder,
        invoiceNumber,
        certificateIds,
        certificateIdError,
        isCertificateImage,
        certificateCreatedDateAfter,
        certificateCreatedDateBefore,
        certificateCreatedDateAfterError,
        certificateCreatedDateBeforeError,
        certificateModifiedDateAfter,
        certificateModifiedDateBefore,
        certificateModifiedDateAfterError,
        certificateModifiedDateBeforeError,
        certificateEffectiveDateAfter,
        certificateEffectiveDateBefore,
        certificateEffectiveDateAfterError,
        certificateEffectiveDateBeforeError,
        certificateExpirationDateAfter,
        certificateExpirationDateAfterError,
        certificateExpirationDateBefore,
        certificateExpirationDateBeforeError,
        certificateCustomFields,
    } = filterState;
    const [certificateStatusPrevVal, setCertificateStatusPrevVal] = useState([]);
    const [certificateRegionPrevVal, setCertificateRegionPrevVal] = useState([]);
    const [certificateReasonPrevVal, setCertificateReasonPrevVal] = useState([]);
    const { selectCertificateStatusRef, selectCertificateRegionsRef, selectexemptReasonsRef } = ref;
    const certificateImageList = [
        {
            label: "Missing a certificate image",
            value: "1",
            selected: isCertificateImage === "1",
        },
        {
            label: "Has a certificate image",
            value: "2",
            selected: isCertificateImage === "2",
        },
    ];
    const handleSearch = async (id, value) => {
        dispatch(
            setFilterState({
                [id]: [...filterState[`${id}`], value.value],
            })
        );
    };
    const handleRemove = async (selectKey, objVal, isDeselectAll) => {
        switch (selectKey) {
            case "certificateStatus": {
                setCertificateStatusPrevVal(
                    certificateStatusPrevVal?.filter(val => val !== objVal.value)
                );
                break;
            }
            case "certificateRegions": {
                if (isDeselectAll) {
                    setCertificateRegionPrevVal([]);
                } else {
                    setCertificateRegionPrevVal(
                        certificateRegionPrevVal?.filter(val => val !== objVal.value)
                    );
                }
                break;
            }
            case "exemptReasons": {
                setCertificateReasonPrevVal(
                    certificateReasonPrevVal?.filter(val => val !== objVal.value)
                );
                break;
            }
            default:
                break;
        }
        dispatch(
            setFilterState({
                [`${selectKey}`]:
                    selectKey === "certificateRegions" && isDeselectAll
                        ? []
                        : filterState[`${selectKey}`]?.filter(option => option !== objVal.value),
            })
        );
    };
    const handleSelectClose = async (fieldId, e) => {
        switch (fieldId) {
            case "certificateStatus": {
                setCertificateStatusPrevVal(e.target.value);
                break;
            }
            case "certificateRegions": {
                setCertificateRegionPrevVal(e.target.value);
                break;
            }
            case "exemptReasons": {
                setCertificateReasonPrevVal(e.target.value);
                break;
            }
            default:
                break;
        }
    };

    const updateVendorCertCustomFieldData = async (customFieldId, customFieldValue) => {
        let value = "";
        if (Array.isArray(customFieldValue)) {
            value = customFieldValue.join("|");
        } else {
            value = customFieldValue;
        }
        let currentCustomFieldData = { ...certificateCustomFields };
        if (customFieldValue === "" || customFieldValue === null) {
            delete currentCustomFieldData[customFieldId];
        } else {
            currentCustomFieldData = {
                ...currentCustomFieldData,
                ...{ [customFieldId]: value },
            };
        }
        await dispatch(
            setFilterState({
                certificateCustomFields: currentCustomFieldData,
            })
        );
    };
    return (
        <div className={isCertificateDetails ? "block" : "hidden"}>
            <SRow>
                <SCol className="pad-bottom-sm">
                    <SelectCertificateStatus
                        ref={selectCertificateStatusRef}
                        onAdd={e => handleSearch("certificateStatus", e)}
                        onRemove={e => handleRemove("certificateStatus", e)}
                        onClose={e => handleSelectClose("certificateStatus", e)}
                        loading={isLoading}
                        value={certificateStatus}
                        disabled={isLoading}
                        multiple
                        isVendor
                    />
                </SCol>
            </SRow>
            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <SelectOutgoingExposureZone
                        ref={selectCertificateRegionsRef}
                        id="certificateRegions"
                        name="certificateRegions"
                        onAdd={e => handleSearch("certificateRegions", e)}
                        onRemove={(eventDetail, isDeselectAll) =>
                            handleRemove("certificateRegions", eventDetail, isDeselectAll)
                        }
                        onClose={e => handleSelectClose("certificateRegions", e)}
                        loading={isLoading}
                        value={certificateRegions}
                        multiple
                        exposureZoneDisabled={false}
                        disabled={isLoading}
                    />
                </SCol>
            </SRow>

            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <SelectOutgoingExemptionReasons
                        ref={selectexemptReasonsRef}
                        name="exemptReasons"
                        id="exemptReasons"
                        onAdd={e => handleSearch("exemptReasons", e)}
                        onRemove={e => handleRemove("exemptReasons", e)}
                        onClose={e => handleSelectClose("exemptReasons", e)}
                        value={exemptReasons}
                        multiple
                        disabled={isLoading}
                    />
                </SCol>
            </SRow>
            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <input
                        id="isPurchaseOrder"
                        name="isPurchaseOrder"
                        type="checkbox"
                        aria-label="Single use purchase order"
                        onChange={e => handleCheckBoxChange(e)}
                        checked={isPurchaseOrder}
                        disabled={isLoading}
                    />
                    <label htmlFor="isPurchaseOrder">
                        Single use purchase order or invoice number
                    </label>
                    <div className={isPurchaseOrder ? "block pad-top-sm" : "hidden"}>
                        <input
                            name="invoicenumber"
                            id="invoiceNumber"
                            aria-label="Single use invoice number"
                            type="text"
                            value={invoiceNumber}
                            onChange={e =>
                                updateFilters("invoiceNumber", e.target.value.trim(), false)
                            }
                            disabled={isLoading}
                        />
                    </div>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <label htmlFor="certificateIds">Certificate IDs</label>
                    <textarea
                        name="certificateIds"
                        id="certificateIds"
                        rows="5"
                        onChange={handleMultilineInputChange("certificateIds")}
                        value={certificateIds}
                        className={certificateIdError ? errorTag : noErrorTag}
                        disabled={isLoading}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {certificateIdError}
                    </div>
                </SCol>
            </SRow>

            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <FeatureToggler category="validatedCertificate" id="customFields">
                        <CertificateCustomField
                            updateCustomFieldPostData={updateVendorCertCustomFieldData}
                            loading={isLoading}
                            searchValues={certificateCustomFields}
                        />
                    </FeatureToggler>
                </SCol>
            </SRow>

            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <label htmlFor="certificateImage" id="lbl-certificateImage">
                        Certificate image
                    </label>
                    <SSelect
                        name="certificateImage"
                        inputId="certificateImage"
                        optionsList={certificateImageList}
                        onS-select={e =>
                            updateFilters("isCertificateImage", e.detail.item.value, false)
                        }
                        onS-deselect={() => updateFilters("isCertificateImage", 0, false)}
                        value={isCertificateImage}
                        selectionOptional
                        disabled={isLoading ? "disabled" : null}
                    />
                </SCol>
            </SRow>
            <SRow>
                <SCol span="6" className="pad-right-xs pad-bottom-sm">
                    <label htmlFor="certificateCreatedDateAfter">Created after</label>
                    <SDatepicker
                        inputId="certificateCreatedDateAfter"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("certificateCreatedDateAfter", e.detail.value, false);
                        }}
                        value={certificateCreatedDateAfter}
                        onS-deselect={() => {
                            updateFilters("certificateCreatedDateAfter", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {certificateCreatedDateAfterError}
                    </div>
                </SCol>
                <SCol span="6" className="pad-left-xs pad-bottom-sm">
                    <label htmlFor="certificateCreatedDateBefore">Created before</label>
                    <SDatepicker
                        inputId="certificateCreatedDateBefore"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("certificateCreatedDateBefore", e.detail.value, false);
                        }}
                        value={certificateCreatedDateBefore}
                        onS-deselect={() => {
                            updateFilters("certificateCreatedDateBefore", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {certificateCreatedDateBeforeError}
                    </div>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="6" className="pad-right-xs pad-bottom-sm">
                    <label htmlFor="certificateModifiedDateAfter">Last modified after</label>
                    <SDatepicker
                        inputId="certificateModifiedDateAfter"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("certificateModifiedDateAfter", e.detail.value, false);
                        }}
                        value={certificateModifiedDateAfter}
                        onS-deselect={() => {
                            updateFilters("certificateLastModifiedDateAfter", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {certificateModifiedDateAfterError}
                    </div>
                </SCol>
                <SCol span="6" className="pad-left-xs pad-bottom-sm">
                    <label htmlFor="certificateModifiedDateBefore">Last modified before</label>
                    <SDatepicker
                        inputId="certificateModifiedDateBefore"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("certificateModifiedDateBefore", e.detail.value, false);
                        }}
                        value={certificateModifiedDateBefore}
                        onS-deselect={() => {
                            updateFilters("certificateModifiedDateBefore", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {certificateModifiedDateBeforeError}
                    </div>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="6" className="pad-right-xs pad-bottom-sm">
                    <label htmlFor="certificateEffectiveDateAfter">Effective date after</label>
                    <SDatepicker
                        inputId="certificateEffectiveDateAfter"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("certificateEffectiveDateAfter", e.detail.value, false);
                        }}
                        value={certificateEffectiveDateAfter}
                        onS-deselect={() => {
                            updateFilters("certificateEffectiveDateAfter", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {certificateEffectiveDateAfterError}
                    </div>
                </SCol>
                <SCol span="6" className="pad-left-xs pad-bottom-sm">
                    <label htmlFor="certificateEffectiveDateBefore">Effective date before</label>
                    <SDatepicker
                        inputId="certificateEffectiveDateBefore"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("certificateEffectiveDateBefore", e.detail.value, false);
                        }}
                        value={certificateEffectiveDateBefore}
                        onS-deselect={() => {
                            updateFilters("certificateEffectiveDateBefore", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {certificateEffectiveDateBeforeError}
                    </div>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="6" className="pad-right-xs pad-bottom-sm">
                    <label htmlFor="certificateExpirationDateAfter">Expiration date after</label>
                    <SDatepicker
                        inputId="certificateExpirationDateAfter"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("certificateExpirationDateAfter", e.detail.value, false);
                        }}
                        value={certificateExpirationDateAfter}
                        onS-deselect={() => {
                            updateFilters("certificateExpirationDateAfter", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {certificateExpirationDateAfterError}
                    </div>
                </SCol>
                <SCol span="6" className="pad-left-xs pad-bottom-sm">
                    <label htmlFor="certificateExpirationDateBefore">Expiration date before</label>
                    <SDatepicker
                        inputId="certificateExpirationDateBefore"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("certificateExpirationDateBefore", e.detail.value, false);
                        }}
                        value={certificateExpirationDateBefore}
                        onS-deselect={() => {
                            updateFilters("certificateExpirationDateBefore", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {certificateExpirationDateBeforeError}
                    </div>
                </SCol>
            </SRow>
        </div>
    );
});

export default FilterByVendorCertificate;
