import { SCol, SLoader, SRow, STag, SIcon } from "@avalara/skylab-react";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
    fetchCampaignAPI,
    selectCampaign,
    selectLoading,
    setCampaign,
    selectTotalCustomers,
    fetchCampaignDetailsAPI,
    selectIsExportCampaignDialogOpen,
} from "../../app/requestSlice";
import { StatusDescriptions } from "../../shared/constants";
import { getFormattedDate } from "../../shared/Utils";
import { selectSession } from "../../app/sessionSlice";
import CustomerQuickSearch from "../sharedComponents/CustomerQuickSearch";
import CampaignGrid from "./CampaignGrid";
import RecordCount from "../search/RecordCount";
import BulkRequestFilter from "../requests/bulkRequest/BulkRequestFilter";
import CampaignExportDialog from "./CampaignExportDialog";

const Campaign = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const campaign = useSelector(selectCampaign, shallowEqual);
    const isLoading = useSelector(selectLoading);
    const totalCustomers = useSelector(selectTotalCustomers);
    const [campaignUndeliverableRecipients, setCampaignUndeliverableRecipients] = useState("0"); // eslint-disable-line no-unused-vars
    const selectCampaignDialogOpen = useSelector(selectIsExportCampaignDialogOpen);
    const session = useSelector(selectSession, shallowEqual);

    const StatusToColor = {
        E: "red-dark",
        I: "green-light",
        C: "green-dark",
        D: "red-dark",
    };

    let CampaignDOM = null;
    useEffect(() => {
        if (params) {
            dispatch(fetchCampaignAPI(params.campaignID));
            dispatch(fetchCampaignDetailsAPI(params.campaignID, session.rowsPerPage));
        }
        return () => {
            dispatch(setCampaign([]));
        };
    }, [params.campaignID, dispatch]);

    useEffect(() => {
        if (campaign) {
            if (campaign.campaignsCustomers) {
                const undeliverables = campaign.campaignsCustomers.filter(
                    customer => customer.emailAddress === "" || customer.emailAddress === null
                );
                setCampaignUndeliverableRecipients(undeliverables.length);
            }
        }
    }, [params.campaignID, campaign, dispatch]);

    if (isLoading) {
        CampaignDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        CampaignDOM = (
            <div>
                <SRow className="margin-bottom-md">
                    <SCol>
                        <CustomerQuickSearch />
                        <h1 className="inline-block">Request: {campaign.title}</h1>
                    </SCol>
                    <SCol>
                        <button
                            className="text-md font-semibold ghost-blue margin-bottom-md margin-top-md icon-leading pad-all-none"
                            onClick={() => navigate("/requests")}>
                            <SIcon name="arrow-left" aria-label="arrow-left" aria-hidden="true" />
                            Back to requests
                        </button>
                    </SCol>
                    <SCol>
                        <STag
                            id={campaign.id}
                            color={StatusToColor[campaign.status]}
                            className="max-content-width"
                            aria-label={StatusDescriptions[campaign.status]}>
                            <span slot="content">{StatusDescriptions[campaign.status]}</span>
                        </STag>
                    </SCol>
                    <SCol span="6">
                        <table className="condensed-table borderless" role="presentation">
                            <tbody>
                                <tr>
                                    <th scope="col">
                                        <label
                                            htmlFor="request-date"
                                            id="lbl-request-date"
                                            className="font-bold inline-block text-xs-strong margin-right-sm">
                                            Request on
                                        </label>
                                    </th>
                                    <td id="request-date">{getFormattedDate(campaign.created)}</td>
                                    <th scope="col">
                                        <label
                                            htmlFor="requested-by"
                                            id="lbl-requested-by"
                                            className="inline-block font-bold text-xs-strong margin-right-sm">
                                            Requested by
                                        </label>
                                    </th>
                                    <td id="requested-by">{campaign.accountName}</td>
                                </tr>
                                <tr>
                                    <th scope="col">
                                        <label
                                            htmlFor="return-by"
                                            id="lbl-return-by"
                                            className="inline-block font-bold text-xs-strong margin-right-sm">
                                            Return by
                                        </label>
                                    </th>
                                    {/* To be added when API is ready */}
                                    <td id="return-by" />
                                    <th scope="col">
                                        <label
                                            id="lbl-delivery-method"
                                            htmlFor="delivery-method"
                                            className="inline-block font-bold text-xs-strong margin-right-sm">
                                            Delivery method
                                        </label>
                                    </th>
                                    <td id="delivery-method">Email</td>
                                </tr>
                                <tr>
                                    <th scope="col" className="pad-right-none">
                                        <label
                                            id="lbl-undeliverable-recipients"
                                            htmlFor="undeliverable-recipients"
                                            className="inline-block font-bold text-xs-strong margin-right-none pad-right-none">
                                            Undeliverable
                                        </label>
                                    </th>
                                    <td id="undeliverable-recipients">
                                        {campaignUndeliverableRecipients}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </SCol>
                </SRow>
                <BulkRequestFilter />
                <RecordCount
                    selectedCount={0}
                    recordCount={totalCustomers}
                    recordName="customers"
                />
                <CampaignGrid />
                {selectCampaignDialogOpen ? <CampaignExportDialog /> : null}
            </div>
        );
    }
    return CampaignDOM;
});

export default Campaign;
