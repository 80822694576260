import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3UrlWithQuery, errorResponse, buildApiV3Url } from "../shared/Utils";
import toast from "../hooks/toast";

export const coverLetterSlice = createSlice({
    name: "coverLetter",
    initialState: {
        isloading: false,
        isTemplateTagsLoading: false,
        coverLetter: [],
        coverLetterPreview: {},
        isCoverLetterPreviewLoading: false,
        isCoverLetterValid: false,
        noDataType: "",
        sortColumn: ["title", true],
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 10,
                totalRecords: 0,
            },
        },
        templateTags: [],
        deliveryType: "E",
    },
    reducers: {
        setCoverLetter: (state, action) => {
            state.coverLetter = action.payload;
        },
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setCoverLetterPreview: (state, action) => {
            state.coverLetterPreview = action.payload;
        },
        setCoverLetterPreviewLoading: (state, action) => {
            state.isCoverLetterPreviewLoading = action.payload;
        },
        clearCoverLetterPreview: state => {
            state.coverLetterPreview = {};
        },
        setIsCoverLetterValid: (state, action) => {
            state.isCoverLetterValid = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: (state, action) => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: action?.payload,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setIsTemplateTagsLoading: (state, action) => {
            state.isTemplateTagsLoading = action.payload;
        },
        setTemplateTags: (state, action) => {
            state.templateTags = action.payload;
        },
        setDeliveryType: (state, action) => {
            state.deliveryType = action.payload;
        },
    },
});

export const {
    setLoading,
    setCoverLetter,
    setCoverLetterPreview,
    setCoverLetterPreviewLoading,
    clearCoverLetterPreview,
    setIsCoverLetterValid,
    setPage,
    setPageToNull,
    setNoDataType,
    setSortColumn,
    setIsTemplateTagsLoading,
    setTemplateTags,
    setDeliveryType,
} = coverLetterSlice.actions;

export const selectCoverLetter = state => {
    return state.coverLetter.coverLetter;
};

export const selectLoading = state => {
    return state.coverLetter.isloading;
};

export const selectCoverLetterPreview = state => {
    return state.coverLetter.coverLetterPreview;
};

export const selectPreviewLoading = state => {
    return state.coverLetter.isCoverLetterPreviewLoading;
};

export const selectIsCoverLetterValid = state => {
    return state.coverLetter.isCoverLetterValid;
};
export const selectPage = state => {
    return state.coverLetter.page;
};
export const selectSortColumn = state => {
    return state.coverLetter.sortColumn;
};
export const selectIsTemplateTagsLoading = state => {
    return state.coverLetter.isTemplateTagsLoading;
};

export const selectTemplateTags = state => {
    return state.coverLetter.templateTags;
};

export const selectDeliveryType = state => {
    return state.coverLetter.deliveryType;
};

const [showToast] = toast();

export const fetchDefaultCoverLetterAPI = () => async (dispatch, getState) => {
    const { coverLetter } = getState();
    if (coverLetter.isloading) {
        return;
    }

    dispatch(setLoading(true));

    const response = await axios.get(buildApiV3Url(`cover-letters?$top=100`), {
        withCredentials: true,
    });

    if (response && response?.data?.value) {
        dispatch(setCoverLetter(response.data.value));
    }
    dispatch(setLoading(false));
};

export const fetchCoverLetterByIdAPI = id => async (dispatch, getState) => {
    const { coverLetter } = getState();
    if (coverLetter.isCoverLetterPreviewLoading) {
        return;
    }

    dispatch(setCoverLetterPreviewLoading(true));
    const response = await axios.get(buildApiV3Url(`cover-letters/${id}`), {
        withCredentials: true,
    });

    if (response && response.data) {
        dispatch(setCoverLetterPreview(response.data));
    }
    dispatch(setCoverLetterPreviewLoading(false));
};

export const fetchOutgoingCoverLetterById = id => async (dispatch, getState) => {
    const { coverLetter } = getState();
    if (coverLetter.isCoverLetterPreviewLoading) {
        return;
    }

    dispatch(setCoverLetterPreviewLoading(true));
    const response = await axios.get(buildApiV3Url(`cover-letters/outgoing/${id}`), {
        withCredentials: true,
    });

    if (response && response.data) {
        dispatch(setCoverLetterPreview(response.data));
    }

    dispatch(setCoverLetterPreviewLoading(false));
};

export const fetchCoverLetterByFiltersAPI = params => async (dispatch, getState) => {
    const { coverLetter } = getState();
    if (coverLetter.isCoverLetterPreviewLoading) {
        return;
    }

    dispatch(setCoverLetterPreviewLoading(true));
    const filterString = params ? `?$filter=${params}&$top=100` : "?$top=100";
    const response = await axios.get(buildApiV3Url(`cover-letters${filterString}`), {
        withCredentials: true,
    });

    if (response?.data?.value) {
        dispatch(setCoverLetter(response?.data?.value));
    }

    dispatch(setCoverLetterPreviewLoading(false));
};

export const fetchOutgoingLettersByAPI = params => async (dispatch, getState) => {
    const { coverLetter } = getState();
    if (coverLetter.isCoverLetterPreviewLoading) {
        return;
    }

    dispatch(setCoverLetterPreviewLoading(true));
    const filterString = params ? `?$filter=${params}&$top=100` : "?$top=100";

    const response = await axios.get(
        buildApiV3Url(`cover-letters/outgoing${filterString}&$orderBy=title+ASC`),
        { withCredentials: true }
    );

    if (response?.data?.value) {
        dispatch(setCoverLetter(response?.data?.value));
        if (response?.data?.value.length > 0 && filterString !== "") {
            dispatch(setCoverLetterPreview(response.data.value[0]));
        }
    }

    dispatch(setCoverLetterPreviewLoading(false));
};

export const fetchCoverLettersByPagingAPI =
    (pageSize = 10, pageNumber = 0, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { coverLetter } = getState();
        if (coverLetter.isCoverLetterPreviewLoading) {
            return;
        }
        dispatch(setCoverLetterPreviewLoading(true));
        const sort = `${coverLetter.sortColumn[0]} ${coverLetter.sortColumn[1] ? "ASC" : "DESC"}`;
        const apiURI = buildApiV3UrlWithQuery(
            `cover-letters?$filter`,
            "",
            "",
            pageSize,
            pageNumber,
            sort
        );
        const response = await axios.get(`${apiURI}`, {
            withCredentials: true,
        });
        if (errorResponse(response)?.code) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull(pageSize));
        } else if (response?.data) {
            dispatch(setNoDataType(""));
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response?.data?.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 10,
                      totalRecords: response?.data?.count,
                  };
            dispatch(
                setPage({
                    data: response?.data?.value,
                    paginate: paginateData,
                })
            );
        } else {
            dispatch(setPageToNull([]));
        }
        dispatch(setCoverLetterPreviewLoading(false));
    };

export const fetchOutgoingCoverLettersByPagingAPI =
    (pageSize = 10, pageNumber = 0, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { coverLetter } = getState();
        if (coverLetter.isCoverLetterPreviewLoading) {
            return;
        }
        dispatch(setCoverLetterPreviewLoading(true));
        const sort = `${coverLetter.sortColumn[0]} ${coverLetter.sortColumn[1] ? "ASC" : "DESC"}`;
        const apiURI = buildApiV3UrlWithQuery(
            `cover-letters/outgoing?$filter`,
            "",
            "",
            pageSize,
            pageNumber,
            sort
        );
        const response = await axios.get(`${apiURI}`, {
            withCredentials: true,
        });
        if (errorResponse(response)?.code) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull(pageSize));
        } else if (response?.data) {
            dispatch(setNoDataType(""));
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response?.data?.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 10,
                      totalRecords: response?.data?.count,
                  };
            dispatch(
                setPage({
                    data: response?.data?.value,
                    paginate: paginateData,
                })
            );
        } else {
            dispatch(setPageToNull([]));
        }
        dispatch(setCoverLetterPreviewLoading(false));
    };

export const postCoverLetter = postData => async (dispatch, getState) => {
    const { coverLetter } = getState();
    if (coverLetter.isloading) {
        return [];
    }
    dispatch(setLoading(true));
    const response = await axios.post(
        buildApiV3Url(`cover-letters`),
        {
            title: postData.title,
            subject: postData.subject,
            htmlContent: postData.htmlContent,
            description: postData.description,
        },
        {
            withCredentials: true,
        }
    );
    if (response.status === 201) {
        dispatch(setIsCoverLetterValid(true));
        showToast("success", "The template has been saved successfully.");
    }
    if (typeof response.data === "string") {
        dispatch(setIsCoverLetterValid(false));
        showToast("error", response.data);
    }
    dispatch(setLoading(false));
    return response;
};

export const postOutgoingCoverLetter = postData => async (dispatch, getState) => {
    const { coverLetter } = getState();
    if (coverLetter.isloading) {
        return [];
    }
    dispatch(setLoading(true));
    const response = await axios.post(
        buildApiV3Url(`cover-letters/outgoing`),
        {
            title: postData.title,
            subject: postData.subject,
            htmlContent: postData.htmlContent,
            description: postData.description,
        },
        {
            withCredentials: true,
        }
    );
    if (response.status === 201) {
        dispatch(setIsCoverLetterValid(true));
        showToast("success", "The template has been saved successfully.");
    }
    if (typeof response.data === "string") {
        dispatch(setIsCoverLetterValid(false));
        showToast("error", response.data);
    }
    dispatch(setLoading(false));
    return response;
};

export const putCoverLetter = putData => async (dispatch, getState) => {
    const { coverLetter } = getState();
    if (coverLetter.isloading) {
        return [];
    }
    dispatch(setLoading(true));
    const response = await axios.put(buildApiV3Url(`cover-letters/${putData.id}`), putData, {
        withCredentials: true,
    });
    if (response) {
        if (typeof response.data === "object") {
            await dispatch(setIsCoverLetterValid(true));
            showToast("success", "The template has been updated successfully.");
        }
        if (typeof response.data === "string") {
            await dispatch(setIsCoverLetterValid(false));
            showToast("error", response.data);
        }
    }
    dispatch(setLoading(false));
    return response;
};

export const deleteCoverLetterAPI = coverLetterId => async (dispatch, getState) => {
    const { coverLetter } = getState();

    if (coverLetter.isloading) return;

    dispatch(setLoading(true));
    const response = await axios.delete(buildApiV3Url(`cover-letters/${coverLetterId}`), {
        withCredentials: true,
    });

    if (response?.data[0]?.description) {
        showToast("success", "The template has been deleted successfully.");
        dispatch(fetchCoverLettersByPagingAPI());
    }

    dispatch(setLoading(false));
};

export const deleteOutgoingCoverLetterAPI = coverLetterId => async (dispatch, getState) => {
    const { coverLetter } = getState();

    if (coverLetter.isloading) return;

    dispatch(setLoading(true));
    const response = await axios.delete(buildApiV3Url(`cover-letters/${coverLetterId}`), {
        withCredentials: true,
    });

    if (response?.data[0]?.description) {
        showToast("success", "The template has been deleted successfully.");
        dispatch(fetchOutgoingCoverLettersByPagingAPI());
    }

    dispatch(setLoading(false));
};

export const fetchTemplateTags = () => async (dispatch, getState) => {
    const { coverLetter } = getState();
    if (coverLetter.isTemplateTagsLoading) {
        return;
    }

    dispatch(setIsTemplateTagsLoading(true));
    const response = await axios.get(buildApiV3Url(`cover-letters/template-tags?documentType=1`), {
        withCredentials: true,
    });

    if (response && response.data) {
        dispatch(setTemplateTags(response.data.value));
    }
    dispatch(setIsTemplateTagsLoading(false));
};

export default coverLetterSlice.reducer;
