import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import toast from "../hooks/toast";
import { buildApiV3UrlWithQuery, fixSearchStringForPostgres, buildApiV3Url } from "../shared/Utils";

const initialState = {
    isloading: false,
    isChildLoading: false,
    isUploading: false,
    noDataType: "",
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 20,
            totalRecords: 0,
        },
    },
    childData: [],
    zipCount: 0,
    sortColumn: ["id", false],
    isIncludeDeleted: false,
    isDeleting: false,
};

export const bulkUploadSlice = createSlice({
    name: "bulkUpload",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setChildLoading: (state, action) => {
            state.isChildLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: (state, action) => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: action?.payload || initialState.page.rowsPerPage,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setChildData: (state, action) => {
            state.childData = action.payload;
        },
        setIsUploding: (state, action) => {
            state.isUploading = action.payload;
        },
        setZipCount: (state, action) => {
            state.zipCount = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setIsIncludeDeleted: (state, action) => {
            state.isIncludeDeleted = action.payload;
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
    },
});

export const {
    setLoading,
    setPage,
    setPageToNull,
    setNoDataType,
    setChildData,
    setChildLoading,
    setIsUploding,
    setZipCount,
    setSortColumn,
    setIsIncludeDeleted,
    setIsDeleting,
} = bulkUploadSlice.actions;

export const selectSortColumn = state => {
    return state.bulkUpload.sortColumn;
};

export const selectZipCount = state => {
    return state.bulkUpload.zipCount;
};

export const selectIsUploading = state => {
    return state.bulkUpload.isUploading;
};

export const selectChildData = state => {
    return state.bulkUpload.childData;
};

export const selectChildLoading = state => {
    return state.bulkUpload.isChildLoading;
};

export const selectLoading = state => {
    return state.bulkUpload.isloading;
};

export const selectPage = state => {
    return state.bulkUpload.page;
};

export const selectNoData = state => {
    return state.bulkUpload.noDataType;
};

export const selectIsIncludeDeleted = state => {
    return state.bulkUpload.isIncludeDeleted;
};

export const selectIsDeleting = state => {
    return state.bulkUpload.isDeleting;
};

const [showToast] = toast();
export const fetchBulkUploadCertificates =
    (filterString, include, top, skip, paginateDetails) => async (dispatch, getState) => {
        const { isloading, sortColumn, isIncludeDeleted } = getState().bulkUpload;
        if (isloading) {
            return;
        }
        dispatch(setLoading(true));
        if (filterString !== undefined && filterString.length !== 0) {
            filterString = fixSearchStringForPostgres(filterString);
            filterString = `filename contains ${filterString}`;
        }

        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
        const apiURI = buildApiV3UrlWithQuery(
            `certificates/bulk-upload-certificates/parent?includeDeleted=${isIncludeDeleted}`,
            filterString,
            include,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });
        if (response?.status === 200 && response?.data?.value !== null) {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(setPage({ data: response.data.value, paginate: paginateData }));
            dispatch(setZipCount(response.data.count));
        } else {
            dispatch(setPageToNull());
        }
        dispatch(setLoading(false));
    };

export const fetchBulkUploadChildCertificates =
    (parentId, searchedValue) => async (dispatch, getState) => {
        const { isChildLoading } = getState().bulkUpload;
        if (isChildLoading) {
            return [];
        }
        dispatch(setChildLoading(true));
        const response = await axios.get(
            buildApiV3Url(`certificates/bulk-upload-certificates/child/${parentId}`),
            { withCredentials: true }
        );
        if (response?.status === 200 && response?.data?.value !== null) {
            if (searchedValue.trim()) {
                const data = response?.data?.filter(child =>
                    child?.filename?.toLowerCase()?.includes(searchedValue.trim().toLowerCase())
                );
                dispatch(setChildData(data));
                dispatch(setChildLoading(false));
                return data;
            }
            dispatch(setChildData(response.data));
        } else {
            showToast("error", "Failed to fetch certificates. Try Again Later.");
        }
        dispatch(setChildLoading(false));
        if (response) return response.data;
        return [];
    };

export const postBulkUploadCertificates =
    (fileData, uploadedFromPending) => async (dispatch, getState) => {
        const { isUploading } = getState().bulkUpload;
        if (isUploading) {
            return;
        }
        dispatch(setIsUploding(true));
        const response = await axios.post(
            buildApiV3Url(`certificates/bulk-upload-certificates`),
            fileData,
            { withCredentials: true }
        );
        if (response?.status === 200 && response?.data?.value !== null) {
            if (uploadedFromPending) {
                const baseURL = `${window.location.origin}`;
                showToast(
                    "success",
                    "ZIP file uploaded. New certificates will appear in the Incoming Certificates to Review page after processing.",
                    "zip-upload-success",
                    false,
                    "Check processing status",
                    () => {
                        window.open(
                            `${baseURL}/search/bulk-upload`,
                            "_blank",
                            "noopener,noreferrer"
                        );
                    }
                );
            } else showToast("success", "Your ZIP file is being processed.");
        } else {
            showToast("error", "Your ZIP file didn’t upload. Check your file and try again.");
        }
        dispatch(setIsUploding(false));
    };

export const deleteBulkUploadCertificate = prarentId => async (dispatch, getState) => {
    const { isDeleting, page } = getState().bulkUpload;
    if (isDeleting) {
        return false;
    }
    dispatch(setIsDeleting(true));
    const response = await axios.put(
        buildApiV3Url(
            `certificates/bulk-upload-certificates/${prarentId}/set-status?status=Deleted`
        ),
        {},
        { withCredentials: true }
    );
    if (response?.status === 200 && response?.data?.value !== null) {
        showToast("success", "The zip upload record is deleted successfully.");
    } else {
        showToast("error", "Zip was not deleted, Please try again.");
    }
    dispatch(
        fetchBulkUploadCertificates(
            "",
            null,
            page.paginate.rowsPerPage,
            page.paginate.startIndex,
            page.paginate
        )
    );
    dispatch(setIsDeleting(false));
    return response?.data;
};

export default bulkUploadSlice.reducer;
