import { SIcon } from "@avalara/skylab-react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCampaignsAPI, selectNoData, selectPage } from "../../../app/requestSlice";
import featureFlag from "../../../featureToggler/featureFlag";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import { selectSession } from "../../../app/sessionSlice";
import { disableLink } from "../../../shared/Utils";
import NoData from "../../search/NoData";
import BulkRequestGrid from "./BulkRequestGrid";

const BulkRequest = React.memo(() => {
    const dispatch = useDispatch();
    const pageData = useSelector(selectPage, shallowEqual);
    const noBulkRequestData = useSelector(selectNoData);
    const createRequestButton = "create-request-btn";
    const isEligibleUserCampaignFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.campaign)
    );
    const session = useSelector(selectSession, shallowEqual);

    useEffect(() => {
        dispatch(fetchCampaignsAPI("", null, session.rowsPerPage, null, true));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEligibleUserCampaignFlag) {
            disableLink(createRequestButton);
        }
    }, [isEligibleUserCampaignFlag]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Bulk requests"))}</title>
            </Helmet>
            <div className="flex justify-content-end margin-top-md margin-bottom-xs">
                <Link to="/requests/create" id={createRequestButton}>
                    <button
                        type="button"
                        className="primary inline"
                        disabled={!isEligibleUserCampaignFlag}
                        aria-disabled={!isEligibleUserCampaignFlag}>
                        <SIcon name="send" className="margin-right-xs" aria-hidden="true" />
                        Request certificates
                    </button>
                </Link>
            </div>
            <div hidden={!pageData.data.length && noBulkRequestData === "onLanding"}>
                <BulkRequestGrid />
            </div>
            <NoData hidden={pageData?.data?.length} request="bulkRequests" />
        </React.Fragment>
    );
});

export default BulkRequest;
