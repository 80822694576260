import React from "react";
import { SRow, SCol } from "@avalara/skylab-react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import "../settingsStyle.css";

const AdvancedSettings = React.memo(() => {
    const dispatch = useDispatch();

    const isEligibleUserSettingsViewExemptionRegion = dispatch(
        isEligibleUser(featureFlag.settings.viewExemptionRegion)
    );
    const isEligibleUserSettingsViewExemptionReason = dispatch(
        isEligibleUser(featureFlag.settings.viewExemptionReason)
    );
    const isEligibleUserSettingsViewInvalidReason = dispatch(
        isEligibleUser(featureFlag.settings.viewInvalidReason)
    );

    const isEligibleUserSettingsManageCustomFields = dispatch(
        isEligibleUser(featureFlag.settings.viewCustomField)
    );

    const isEligibleUserSettingsViewLabels = dispatch(
        isEligibleUser(featureFlag.settings.viewLabels)
    );

    const isEligibleUserSettingsManageProductCategories = dispatch(
        isEligibleUser(featureFlag.settings.viewProductCategories)
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Settings : Advanced settings"))}</title>
            </Helmet>
            <div className="margin-top-sm">
                <SRow>
                    <SCol>
                        <h3 className="margin-bottom-none">Where you collect certificates </h3>
                        <p className="margin-top-none">
                            <a
                                href="/settings/manage-regions"
                                className={
                                    !isEligibleUserSettingsViewExemptionRegion
                                        ? "disable-anchor-tag"
                                        : ""
                                }>
                                Manage the regions
                            </a>{" "}
                            where you collect certificates from customers, also known as exposure
                            zones.
                        </p>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <h3 className="margin-bottom-none">Exempt reasons </h3>
                        <p className="margin-top-none">
                            <a
                                href="/settings/manage-exempt-reasons"
                                className={
                                    !isEligibleUserSettingsViewExemptionReason
                                        ? "disable-anchor-tag"
                                        : ""
                                }>
                                Manage the reasons
                            </a>{" "}
                            that are available when saving a certificate as valid.
                        </p>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <h3 className="margin-bottom-none">Invalid reasons </h3>
                        <p className="margin-top-none">
                            <a
                                href="/settings/manage-invalid-reasons"
                                className={
                                    !isEligibleUserSettingsViewInvalidReason
                                        ? "disable-anchor-tag"
                                        : ""
                                }>
                                Manage the invalid reasons
                            </a>{" "}
                            that are available when saving a certificate as invalid.
                        </p>
                    </SCol>
                </SRow>

                <FeatureToggler category="settings" id="viewCustomField">
                    <SRow>
                        <SCol>
                            <h3 className="margin-bottom-none">Custom fields</h3>
                            <p className="margin-top-none">
                                <Link
                                    to="/settings/custom-fields/certificates-custom-fields"
                                    className={
                                        !isEligibleUserSettingsManageCustomFields
                                            ? "disable-anchor-tag"
                                            : ""
                                    }>
                                    Manage the custom fields
                                </Link>{" "}
                                used to add additional information to customers and certificates.
                            </p>
                        </SCol>
                    </SRow>
                </FeatureToggler>
                <FeatureToggler category="settings" id="viewLabels">
                    <SRow>
                        <SCol>
                            <h3 className="margin-bottom-none">Labels</h3>
                            <p className="margin-top-none">
                                <Link
                                    to="/settings/labels/certificate-labels"
                                    className={
                                        !isEligibleUserSettingsViewLabels
                                            ? "disable-anchor-tag"
                                            : ""
                                    }>
                                    Manage the labels{" "}
                                </Link>
                                used to add attributes to customers and certificates.
                            </p>
                        </SCol>
                    </SRow>
                </FeatureToggler>
                <FeatureToggler category="settings" id="viewProductCategories">
                    <SRow>
                        <SCol>
                            <h3 className="margin-bottom-none">Exemption by product category</h3>
                            <p className="margin-top-none">
                                <a
                                    href="/settings/manage-product-categories"
                                    className={
                                        !isEligibleUserSettingsManageProductCategories
                                            ? "disable-anchor-tag"
                                            : ""
                                    }>
                                    Manage product categories and settings{" "}
                                </a>
                                used to determine whether certain products are taxed.
                            </p>
                        </SCol>
                    </SRow>
                </FeatureToggler>

                <FeatureToggler category="settings" id="viewRetailLocations">
                    <SRow>
                        <SCol>
                            <h3 className="margin-bottom-none">
                                Retail site locations and settings
                            </h3>
                            <p className="margin-top-none">
                                <a href="/settings/retail/locations">
                                    Manage settings and retail sites{" "}
                                </a>
                                that use Avalara.
                            </p>
                        </SCol>
                    </SRow>
                </FeatureToggler>
            </div>
        </React.Fragment>
    );
});

export default AdvancedSettings;
