import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { SIcon } from "@avalara/skylab-react";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import SingleRequestGrid from "./SingleRequestGrid";
import {
    fetchCertificateRequestsAPI,
    selectPage,
    selectNoData,
} from "../../../app/certificateRequestSlice";
import { selectSession } from "../../../app/sessionSlice";
import NoData from "../../search/NoData";
import RequestDialog from "../../sharedDialogs/RequestDialog";
import featureFlag from "../../../featureToggler/featureFlag";

const SingleRequest = React.memo(() => {
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const pageData = useSelector(selectPage, shallowEqual);
    const noSingleRequestData = useSelector(selectNoData);
    const isEligibleUserRequestFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.singleRequest)
    );
    const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
    useEffect(() => {
        dispatch(fetchCertificateRequestsAPI("", null, session.rowsPerPage, null, true));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Single requests"))}</title>
            </Helmet>
            <div className="flex justify-content-end margin-top-md margin-bottom-xs">
                <button
                    type="button"
                    className="primary inline"
                    disabled={!isEligibleUserRequestFlag}
                    onClick={() => setIsRequestDialogOpen(true)}>
                    <SIcon name="send" className="margin-right-xs" aria-hidden="true" />
                    Request certificate
                </button>
            </div>
            <div hidden={!pageData.data.length && noSingleRequestData === "onLanding"}>
                <SingleRequestGrid />
            </div>
            <NoData hidden={pageData.data.length} request="singleRequests" />

            {isRequestDialogOpen ? (
                <RequestDialog
                    isRequestDialogOpen={isRequestDialogOpen}
                    setIsRequestDialogOpen={setIsRequestDialogOpen}
                    customerCount={1}
                    customer={null}
                    fromSingleRequests
                />
            ) : null}
        </React.Fragment>
    );
});

export default SingleRequest;
