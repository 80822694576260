import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SCol, SInputExtended } from "@avalara/skylab-react";
import {
    selectFormState,
    setFormState,
    fetchSavedSearchesAPI,
} from "../../../app/savedSearchesSlice";
import { selectSession } from "../../../app/sessionSlice";

const SavedSearchForm = React.memo(() => {
    const dispatch = useDispatch();
    const formState = useSelector(selectFormState);
    const { nameOrCode } = formState;
    const session = useSelector(selectSession, shallowEqual);

    const handleSubmitForm = () => {
        dispatch(fetchSavedSearchesAPI(session.rowsPerPage));
    };

    const clearSearch = () => {
        dispatch(
            setFormState({
                ...formState,
                nameOrCode: "",
            })
        );
        handleSubmitForm();
    };

    const handleInput = el => {
        dispatch(
            setFormState({
                ...formState,
                [el.detail.inputId]: el.detail.value.trim(),
            })
        );
        setTimeout(() => {
            handleSubmitForm();
        }, 400);
    };

    const handleSearch = async el => {
        await dispatch(
            setFormState({
                ...formState,
                [el.detail.inputId]: el.detail.value.trim(),
            })
        );
        handleSubmitForm();
    };

    const searchBoxDOM = (
        <SCol span="4" className="pad-left-none">
            <label htmlFor="nameOrCode">Search by report name</label>
            <SInputExtended
                inputId="nameOrCode"
                aria-label="search"
                type="search"
                onS-input={e => handleInput(e)}
                onS-search={e => handleSearch(e)}
                onS-clear={() => clearSearch()}
                value={nameOrCode}
            />
        </SCol>
    );

    return searchBoxDOM;
});
export default SavedSearchForm;
