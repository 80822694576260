import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3UrlWithQuery, errorResponse, buildApiV3Url } from "../shared/Utils";
import toast from "../hooks/toast";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    enableLoading: "",
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    sortColumn: ["name", true],
    formState: {
        name: "",
    },
};

export const customerLabelSlice = createSlice({
    name: "customerLabel",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
        setEnableLoading: (state, action) => {
            state.enableLoading = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
    },
});

const [showToast] = toast();
export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setIsAddOrEditLoading,
    setEnableLoading,
    setSortColumn,
} = customerLabelSlice.actions;

export const selectIsLoading = state => {
    return state.customerLabel.isLoading;
};
export const selectPage = state => {
    return state.customerLabel.page;
};
export const selectFormState = state => {
    return state.customerLabel.formState;
};
export const selectIsDeleting = state => {
    return state.customerLabel.isDeleting;
};
export const selectIsAddOrEditLoading = state => {
    return state.customerLabel.isAddOrEditLoading;
};
export const selectEnableLoading = state => {
    return state.customerLabel.enableLoading;
};
export const selectSortColumn = state => {
    return state.customerLabel.sortColumn;
};

export const fetchCustomerLabels =
    (top = 20, skip, paginateDetails) =>
    async (dispatch, getState) => {
        const { isLoading, sortColumn, formState } = getState().customerLabel;
        const { name } = formState;

        if (isLoading) {
            return;
        }
        let filterString = "";
        dispatch(setIsLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
        if (name) {
            filterString = `name contains '${name}'`;
        }

        const apiURI = buildApiV3UrlWithQuery(
            `customer-labels`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (errorResponse(response)?.code?.toLowerCase() === "objectnotfound") {
            dispatch(setPageToNull());
            dispatch(setIsLoading(false));
            return;
        }

        if (response.data && response.data.count === "0") {
            dispatch(setPageToNull());
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setIsLoading(false));
    };

export const deleteCustomerLabel = id => async (dispatch, getState) => {
    const { isDeleting } = getState().customerLabel;
    if (isDeleting) {
        return false;
    }
    dispatch(setIsDeleting(true));
    const response = await axios.delete(buildApiV3Url(`customer-labels/${id}`), {
        withCredentials: true,
    });
    if (response?.data?.success) {
        showToast("success", "Label deleted", "deleteCustomerLabelToast", false);
        dispatch(fetchCustomerLabels());
    }
    dispatch(setIsDeleting(false));
    return response?.data?.success;
};

export const addOrUpdateCustomerLabels =
    (addOrEdit, payload, customerLabelId) => async dispatch => {
        dispatch(setIsAddOrEditLoading(true));
        let success = false;
        if (addOrEdit === "Add") {
            await axios
                .post(buildApiV3Url(`customer-labels`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (!x.data.success) {
                        showToast("error", `Label already exists.`, "label-error-toast", false);
                    }
                    if (x.status === 200 && x.data.success) {
                        showToast("success", `Label added.`, "label-success-toast", false);
                        success = true;
                        dispatch(fetchCustomerLabels());
                    }
                });
        } else {
            await axios
                .put(buildApiV3Url(`customer-labels/${customerLabelId}`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (x.name === "AxiosError" && x.response.status === 400) {
                        showToast(
                            "error",
                            x.response.data.Message,
                            "customer-label-error-toast",
                            false
                        );
                    }
                    if (x.status === 200) {
                        if (!x?.data?.success) {
                            showToast(
                                "error",
                                `${x?.data?.message}`,
                                "customer-label-success-toast",
                                false
                            );
                        } else {
                            showToast(
                                "success",
                                `Successfully updated the label`,
                                "customer-label-success-toast",
                                false
                            );
                        }
                        success = true;
                        dispatch(fetchCustomerLabels());
                    }
                });
        }
        dispatch(setIsAddOrEditLoading(false));
        return success;
    };

export default customerLabelSlice.reducer;
