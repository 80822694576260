import React, { useState, useEffect } from "react";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { capitalize } from "lodash";

import { STag, SPagination, SLoader } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import {
    selectPage,
    selectIsLoadingCertificateRequestForm,
    fetchCertificateRequestFormsAPI,
    selectNoDataType,
    setCertificateFormsDialogOpen,
    selectCertificateFormsDialogOpen,
    selectSortColumn,
    setSortColumn,
    setPageToNull,
} from "../../../../app/settingsSlice";

import CertificateFormsDialog from "./CertificateFormsDialog";
import expirationTimeFrames from "./ExpirationTimeFrames";
import { selectSession, updateRowCountPreference } from "../../../../app/sessionSlice";
import { getRowsPerPage } from "../../../../shared/Utils";

const CertificateOutgoingRequestFormsGrid = React.memo(() => {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoadingCertificateRequestForm);
    const session = useSelector(selectSession, shallowEqual);
    const noDataType = useSelector(selectNoDataType);
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page?.data;
    const paginateData = page.paginate;
    const certificateFormsDialogOpen = useSelector(selectCertificateFormsDialogOpen);
    const [certificateForm, setCertificateForm] = useState({});
    const sortColumn = useSelector(selectSortColumn);

    useEffect(() => {
        dispatch(fetchCertificateRequestFormsAPI(true, true, session.rowsPerPage));
        return () => {
            dispatch(setPageToNull([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getExpirationTimeFrame = timeFrame => {
        const expirationTimeFrame = expirationTimeFrames?.find(x => x.id === timeFrame);

        if (expirationTimeFrame) return expirationTimeFrame.label;

        return timeFrame;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            dispatch(
                fetchCertificateRequestFormsAPI(
                    true,
                    true,
                    e.detail.rowsPerPage,
                    e.detail.startIndex,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };

    async function sortFunction(e) {
        setSubmitting(true);
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        dispatch(fetchCertificateRequestFormsAPI(true, true, session.rowsPerPage));
        setSubmitting(false);
    }

    const cellValueHandler = (key, val) => {
        const regionName = capitalize(key.regionName);
        const reasonName = key.exemptReasonName.toLowerCase();
        const result = `Upload the document that you want to use for ${regionName} ${reasonName}`;

        let btnRender = (
            <button
                className="ghost-blue margin-all-none pad-all-none"
                onClick={() => {
                    setCertificateForm(key);
                    dispatch(setCertificateFormsDialogOpen(true));
                }}>
                {result}
            </button>
        );
        if (key.templateTag !== null && key.staticFile == null && key.formName !== null) {
            btnRender = val;
        } else if (key.staticFile !== null) {
            btnRender = "Static document";
        }
        return btnRender;
    };

    const columns = [
        gridHeader(
            "Region",
            "regionName",
            value => <div id="region">{value}</div>,
            null,
            null,
            null,
            null,
            sortFunction,
            "region.name"
        ),
        gridHeader("Reason for exemption", "exemptReasonName", value => value, null, "", null),
        gridHeader(
            "Form",
            "formName",
            (value, row) => cellValueHandler(row, value),
            null,
            "",
            null
        ),
        gridHeader(
            "Expiration timeframe",
            "timeLength",
            value => getExpirationTimeFrame(value),
            null,
            "",
            null
        ),
        gridHeader(
            "Avalara defaults?",
            "setToDefault",
            value =>
                value ? (
                    <div>
                        <STag id={`tag-${uuidv4()}`} color="blue-light" className="text-ellipsis">
                            <span slot="content">Yes</span>
                        </STag>
                    </div>
                ) : (
                    <div>
                        <STag id={`tag-${uuidv4()}`} className="text-ellipsis">
                            <span slot="content">No</span>
                        </STag>
                    </div>
                ),
            "text-center",
            "text-center",
            null
        ),

        gridHeader(
            "",
            "",
            (value, row) => (
                <button
                    type="button"
                    className="secondary small"
                    id="outgoing-certificate-details"
                    onClick={() => {
                        setCertificateForm(row);
                        dispatch(setCertificateFormsDialogOpen(true));
                    }}>
                    Details
                </button>
            ),
            "text-right",
            "text-right",
            null
        ),
    ];

    const getRowKey = () => {
        return uuidv4();
    };

    let noDataMsg = "";
    switch (noDataType) {
        case "onLanding":
            noDataMsg = (
                <div id="no-data-landing-header" className=" margin-all-xl">
                    <div id="no-certificate-request-found" className="text-center color-gray-dark">
                        <div className="font-semibold font-normal text-lg">No forms found</div>
                        <div className="text-sm">
                            Tell us how you&apos;re exempt, where you&apos;re exempt, and which
                            forms you want to send your vendors.
                        </div>
                    </div>
                </div>
            );
            break;
        case "onSearch":
            noDataMsg = (
                <div className=" margin-all-xl">
                    <div id="no-certificate-request-found" className="text-center color-gray-dark">
                        <div className="font-semibold font-normal text-lg">No forms found</div>
                        <div>Adjust your search or add a region and reason.</div>
                    </div>
                </div>
            );
            break;
        default:
            noDataMsg = null;
    }

    let table = null;

    if (submitting || loading) {
        table = (
            <div className="flex flex-dir-col dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="grid-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (noDataType) {
        table = noDataMsg;
    } else
        table = (
            <s-table-container key={uuidv4()} class="margin-top-xl" id="th-with-filter">
                <DataGrid
                    columns={columns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={loading}
                    sortColumn={sortColumn}
                    inContainer
                />
            </s-table-container>
        );

    return (
        <React.Fragment>
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={getRowsPerPage(pageData, session.rowsPerPage)}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {certificateFormsDialogOpen ? (
                <CertificateFormsDialog
                    outgoing
                    certificateForm={certificateForm}
                    id="outgoing-certificate-details-dialog"
                />
            ) : null}
        </React.Fragment>
    );
});

export default CertificateOutgoingRequestFormsGrid;
