import { SCol, SIcon, SLoader, SRow, SSelect } from "@avalara/skylab-react";
import classnames from "classnames";
import { upperFirst } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    selectCountries,
    selectDefaultCountryId,
    setRedirectURL,
} from "../../app/certificateSlice";
import {
    getCostCentersAsync,
    getItemsAsync,
    getLocationAsync,
    selectCostCenters,
    selectIsLoadingCostCenters,
    selectIsLoadingItems,
    selectIsLoadingLocations,
    selectItems,
    selectLocations,
    setCostCenters,
    setItems,
    setLocations,
} from "../../app/commonSlice";
import {
    customFieldsAPI,
    getCustomerMissingCertRegion,
    getStateByCountry,
    getVendorMissingCertRegion,
    selectCustomer,
    selectFormUpdated,
    selectIsShipFromStateLoading,
    selectIsShipToStateLoading,
    selectLoading,
    selectShipFromStates,
    selectShipFromStatesByCountryName,
    selectShipToStates,
    selectShipToStatesByCountryName,
    selectStates,
    setCustomer,
} from "../../app/customerSlice";
import { selectSetting } from "../../app/settingsSlice";
import axios from "../../axios";
import featureFlag from "../../featureToggler/featureFlag";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import toast from "../../hooks/toast";
import {
    essentialsOrAbove,
    getPageTitle,
    isEligibleUser,
    vendorExemptions,
} from "../../shared/sessionUtility";
import {
    errorTag,
    noErrorTag,
    urlToPageMap,
    validateEmail,
    errorResponse,
    buildApiV3Url,
} from "../../shared/Utils";
import CustomerCustomField from "../sharedComponents/CustomerCustomField";
import CustomerLabel from "../sharedComponents/CustomerLabel";
import SelectCountry from "../sharedComponents/SelectCountry";
import DeleteCustomerDialog from "../sharedDialogs/DeleteCustomerDialog";
import SyncErrorDialog from "../sharedDialogs/SyncErrorDialog";
import "./customer.css";
import ValidationError from "../sharedComponents/ValidationError";
import SelectState from "./SelectState";

const Customer = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const customerCustomFieldRef = useRef();
    const settings = useSelector(selectSetting, shallowEqual);
    const customer = useSelector(selectCustomer, shallowEqual);
    const states = useSelector(selectStates, shallowEqual);
    const isFormUpdated = useSelector(selectFormUpdated);
    const navigate = useNavigate();
    const location = useLocation();
    const vendorCheck = !!(props.setVendor || customer.isVendor);
    const docName = "Sales and Use Tax";
    const defaultCountryId = useSelector(selectDefaultCountryId);
    const initialState = {
        customerNumber: customer.customerNumber ? customer.customerNumber : "",
        customerName: customer.name ? customer.name : "",
        alternateId: customer.alternateId ? customer.alternateId : "",
        country: customer.country?.id,
        state: customer.state?.id,
        addressLine1: customer.addressLine1 ? customer.addressLine1 : "",
        addressLine2: customer.addressLine2 ? customer.addressLine2 : "",
        city: customer.city ? customer.city : "",
        zipCode: customer.zip ? customer.zip : "",
        fein: customer.feinNumber ? customer.feinNumber : "",
        contactName: customer.contactName ? customer.contactName : "",
        phoneNumber: customer.phoneNumber ? customer.phoneNumber : "",
        emailAddress: customer.emailAddress ? customer.emailAddress : "",
        faxNumber: customer.faxNumber ? customer.faxNumber : "",
        isBillTo: customer.isBillTo ? customer.isBillTo : false,
        isShipTo: customer.isShipTo ? customer.isShipTo : false,
        isVendor: vendorCheck,
        costCenterId: customer?.vendorAdditionalInfos?.costCenterId
            ? customer.vendorAdditionalInfos?.costCenterId
            : 0,
        costCenterCode: customer?.vendorAdditionalInfos?.costCenterCode
            ? customer?.vendorAdditionalInfos?.costCenterCode
            : "",
        itemCodeId: customer?.vendorAdditionalInfos?.itemCodeId
            ? customer.vendorAdditionalInfos?.itemCodeId
            : 0,
        itemCode: customer?.vendorAdditionalInfos?.itemCode
            ? customer?.vendorAdditionalInfos?.itemCode
            : "",
        defaultTaxExemption: customer?.vendorAdditionalInfos?.defaultTaxExemption
            ? customer.vendorAdditionalInfos?.defaultTaxExemption
            : "",
        shipFromLocationId: customer?.vendorAdditionalInfos?.shipFromLocationId
            ? customer.vendorAdditionalInfos?.shipFromLocationId
            : 0,
        shipFromLocationCode: customer?.vendorAdditionalInfos?.shipFromLocationCode
            ? customer.vendorAdditionalInfos?.shipFromLocationCode
            : "",
        shipFromAddressLine: customer?.vendorAdditionalInfos?.shipFromAddressLine
            ? customer.vendorAdditionalInfos?.shipFromAddressLine
            : "",
        shipFromAddressCity: customer?.vendorAdditionalInfos?.shipFromAddressCity
            ? customer.vendorAdditionalInfos?.shipFromAddressCity
            : "",
        shipFromAddressState: customer?.vendorAdditionalInfos?.shipFromAddressState
            ? customer.vendorAdditionalInfos?.shipFromAddressState
            : "",
        shipFromAddressZip: customer?.vendorAdditionalInfos?.shipFromAddressZip
            ? customer.vendorAdditionalInfos?.shipFromAddressZip
            : "",
        shipFromAddressCountry: customer?.vendorAdditionalInfos?.shipFromAddressCountry
            ? customer.vendorAdditionalInfos?.shipFromAddressCountry
            : "",

        shipToLocationId: customer?.vendorAdditionalInfos?.shipToLocationId
            ? customer.vendorAdditionalInfos?.shipToLocationId
            : 0,
        shipToLocationCode: customer?.vendorAdditionalInfos?.shipToLocationCode
            ? customer.vendorAdditionalInfos?.shipToLocationCode
            : "",
        shipToAddressLine: customer?.vendorAdditionalInfos?.shipToAddressLine
            ? customer.vendorAdditionalInfos?.shipToAddressLine
            : "",
        shipToAddressCity: customer?.vendorAdditionalInfos?.shipToAddressCity
            ? customer.vendorAdditionalInfos?.shipToAddressCity
            : "",
        shipToAddressState: customer?.vendorAdditionalInfos?.shipToAddressState
            ? customer.vendorAdditionalInfos?.shipToAddressState
            : "",
        shipToAddressZip: customer?.vendorAdditionalInfos?.shipToAddressZip
            ? customer.vendorAdditionalInfos?.shipToAddressZip
            : "",
        shipToAddressCountry: customer?.vendorAdditionalInfos?.shipToAddressCountry
            ? customer.vendorAdditionalInfos?.shipToAddressCountry
            : "",
        isTrustedVendor: customer?.vendorAdditionalInfos?.isTrustedVendor,
        isAccrual: customer?.vendorAdditionalInfos?.isAccrual,
        isTaxOnVendor: customer?.vendorAdditionalInfos?.isTaxOnVendor,
    };
    const [customerNumber, setCustomerNumber] = useState(initialState.customerNumber);
    const [id, setCustomerId] = useState(0);
    const [customerUpdated, setCustomerUpdated] = useState(false);
    const [customerNumberError, setCustomerNumberError] = useState("");
    const [customerName, setCustomerName] = useState(initialState.customerName);
    const [customerNameError, setCustomerNameError] = useState("");
    const [country, setCountry] = useState(initialState.country);
    const [countryError, setCountryError] = useState("valid");
    const [alternateId, setAlternateId] = useState(initialState.alternateId);
    const [alternateIdError, setAlternateIdError] = useState("");
    const [addressLine1, setAddressLine1] = useState(initialState.addressLine1);
    const [addressLine1Error, setAddressLine1Error] = useState("");
    const [addressLine2, setAddressLine2] = useState(initialState.addressLine2);
    const [addressLine2Error, setAddressLine2Error] = useState("");
    const [city, setCity] = useState(initialState.city);
    const [cityError, setCityError] = useState("");
    const [state, setState] = useState(initialState.state);
    const [stateError, setStateError] = useState("valid");
    const [zipCode, setZipCode] = useState(initialState.zipCode);
    const [zipCodeError, setZipCodeError] = useState("");
    const isLoading = useSelector(selectLoading);
    const [submitting, setSubmitting] = useState(false);
    const [showingAddressLine2, showAddressLine2] = useState(false);
    const saveCustomerButton = classnames({ primary: true, large: true, loading: submitting });
    const addSaveCustomerButton = classnames({ secondary: true, large: true, loading: submitting });
    const cancelButton = classnames({ tertiary: true, large: true });
    const [formUpdated, setFormUpdated] = useState(false);
    const [showToast, hideToast, hideAllToast] = toast();
    const title = dispatch(getPageTitle("Add a customer"));
    const titleCustomer = dispatch(getPageTitle(customerName));
    const [fein, setFein] = useState(initialState.fein);
    const [contactName, setContactName] = useState(initialState.contactName);
    const [contactNameError, setContactNameError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(initialState.phoneNumber);
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [emailAddress, setEmailAddress] = useState(initialState.emailAddress);
    const [emailError, setEmailError] = useState("");
    const [faxNumber, setFaxNumber] = useState(initialState.faxNumber);
    const [faxNumberError, setFaxNumberError] = useState("");
    const [isDeleteCustomerDialogOpen, setIsDeleteCustomerDialogOpen] = useState(false);
    const [isCustomerWithCertificate, setIsCustomerWithCertificate] = useState(false);
    const isEssentialsOrAbove = dispatch(essentialsOrAbove());
    const isVendorExemptions = dispatch(vendorExemptions());

    const isEligibleUserManageCustomerFlag = dispatch(
        isEligibleUser(featureFlag.customers.manageCustomer)
    );
    const isEligibleUserManageVendorFlag = dispatch(
        isEligibleUser(featureFlag.vendors.manageVendors)
    );
    const isEligibleUserVendorAdditionalInfo = dispatch(
        isEligibleUser(featureFlag.vendors.vendorAdditionalInfo)
    );

    const isAvaTaxAp =
        isEligibleUserVendorAdditionalInfo && !isEssentialsOrAbove && !isVendorExemptions;

    const [logEntry, setLogEntry] = useState("");
    const [isSyncErrorDialogOpen, setIsSyncErrorDialogOpen] = useState(false);
    const [isLabelUpdated, setIsLabelUpdated] = useState(false);
    const [labelPostData, setLabelPostData] = useState();
    const entityType = vendorCheck ? "vendor" : "customer";

    let CustomerDOM = null;
    const isDisplayedAsDialog = !!ref?.current;
    const toastPersistenceAndMessage = [
        true,
        `Go to ${entityType} search`,
        () => {
            hideToast("customer-success");
            navigate(`/search/${entityType}s`);
        },
    ];
    const LocationOptions = useSelector(selectLocations, shallowEqual);
    const ItemOptions = useSelector(selectItems, shallowEqual);
    const CostCenterOptions = useSelector(selectCostCenters, shallowEqual);
    const isLocationsLoading = useSelector(selectIsLoadingLocations);
    const isItemsLoading = useSelector(selectIsLoadingItems);
    const isCostCenterLoading = useSelector(selectIsLoadingCostCenters);

    const getNameById = (array, element) => {
        return array?.find(x => x.value === element);
    };
    const shipFromCountries = useSelector(selectCountries, shallowEqual).map(element => {
        return { label: element.name, value: element.id };
    });

    const countries = useSelector(selectCountries, shallowEqual);

    const [shipFromCountry, setShipFromCountry] = useState(initialState.shipFromAddressCountry);
    const isLoadingShipFromStates = useSelector(selectIsShipFromStateLoading);
    const ShipFromStates = useSelector(selectShipFromStates, shallowEqual);

    const [shipFromAddressLine1, setShipFromAddressLine1] = useState(
        initialState.shipFromAddressLine
    );
    const [shipFromCity, setShipFromCity] = useState(initialState.shipFromAddressCity);
    const [shipFromState, setShipFromState] = useState(initialState.shipFromAddressState);
    const [shipFromZipCode, setShipFromZipCode] = useState(initialState.shipFromAddressZip);
    const [shipToCountry, setShipToCountry] = useState(initialState.shipToAddressCountry);
    const isLoadingShipToStates = useSelector(selectIsShipToStateLoading);
    const ShipToStates = useSelector(selectShipToStates, shallowEqual);
    const [shipToAddressLine1, setShipToAddressLine1] = useState(initialState.shipToAddressLine);
    const [shipToCity, setShipToCity] = useState(initialState.shipToAddressCity);
    const [shipToState, setShipToState] = useState(initialState.shipToAddressState);
    const [shipToZipCode, setShipToZipCode] = useState(initialState.shipToAddressZip);
    const [shipFromLocationId, setShipFromLocationId] = useState(initialState.shipFromLocationId);
    const [shipToLocationId, setShipToLocationId] = useState(initialState.shipToLocationId);

    const [shipFromLocationCode, setShipFromLocationCode] = useState(
        initialState.shipFromLocationCode
    );
    const [shipToLocationCode, setShipToLocationCode] = useState(initialState.shipToLocationCode);

    const [selectedFromLocationField, setSelectedFromLocationField] = useState(
        initialState?.shipFromLocationId ? "shipFromLocationId" : ""
    );
    const [selectedToLocationField, setSelectedToLocationField] = useState(
        initialState?.shipToLocationId ? "shipToLocationId" : ""
    );

    const [itemCodeId, setItemCodeId] = useState(initialState.itemCodeId);
    const [costCenter, setCostCenter] = useState(initialState.costCenterId);
    const { costCenterCode, itemCode } = initialState;
    const [selectedVendorStatus, setSelectedVendorStatus] = useState("");
    const getLocationOptionList = (locationId, locationCode) => {
        const optionList = LocationOptions?.map(element => {
            const selectOption = {
                label: element.locationCode,
                value: element.id,
                selected: element.id === locationId || element.locationCode === locationCode,
            };
            return selectOption;
        });
        return optionList;
    };
    const getItemOptionList = (itemId, itemCodeValue) => {
        const optionList = ItemOptions?.map(element => {
            const selectOption = {
                label: element.itemCode.substring(0, 50),
                value: element.id,
                selected:
                    element.id === itemId ||
                    (itemCodeValue.length > 0 && element.itemCode === itemCodeValue),
            };
            return selectOption;
        });
        return optionList;
    };

    const getCostCenterOptionList = (costCenterId, costCenterCodeValue = "") => {
        const optionList = CostCenterOptions?.map(element => {
            const selectOption = {
                label: element.costCenterCode.substring(0, 50),
                value: element.costCenterId,
                selected:
                    element.costCenterId === costCenterId ||
                    (costCenterCodeValue.length > 0 &&
                        element.costCenterCode === costCenterCodeValue),
            };
            return selectOption;
        });
        return optionList;
    };

    const getShipFromStateOptionList = stateName => {
        const optionList = ShipFromStates?.map(element => {
            const selectOption = {
                label: element.name,
                value: element.id,
                selected: element.name === stateName,
            };
            return selectOption;
        });
        return optionList;
    };

    const getShipToStateOptionList = stateName => {
        const optionList = ShipToStates?.map(element => {
            const selectOption = {
                label: element.name,
                value: element.id,
                selected: element.name === stateName,
            };
            return selectOption;
        });
        return optionList;
    };

    const handleRadioChange = e => {
        if (e.target.id === "shipFromLocationId" || e.target.id === "shipFromLocationAddress") {
            setSelectedFromLocationField(e.target.value);
        } else if (e.target.id === "shipToLocationId" || e.target.id === "shipToLocationAddress") {
            setSelectedToLocationField(e.target.value);
        } else {
            setSelectedVendorStatus(e.target.value);
        }

        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    useEffect(() => {
        const info = props?.customerInfo;
        if (info) {
            setCustomerUpdated(false);
            const infocustomerNumber = info?.customerNumber ? info?.customerNumber : "";
            let infoname = "";
            const infocountry = info?.country?.name ? info?.country.id : "";
            const infostate = info?.state?.name ? info?.state.id : "";
            const infoalternateId = info?.alternateId ? info?.alternateId : "";
            const infoaddressLine1 = info?.addressLine1 ? info?.addressLine1 : "";
            const infoaddressLine2 = info?.addressLine2 ? info?.addressLine2 : "";
            const infocity = info?.city ? info?.city : "";
            let infozip = "";
            const infofeinNumber = info?.feinNumber ? info?.feinNumber : "";
            const infocontactName = info?.contactName ? info?.contactName : "";
            const infophoneNumber = info?.phoneNumber ? info?.phoneNumber : "";
            const infoemailAddress = info?.emailAddress ? info?.emailAddress : "";
            const infofaxNumber = info?.faxNumber ? info?.faxNumber : "";
            if (info?.name) {
                infoname = info.name;
            } else if (info?.customerName) {
                infoname = info.customerName;
            }
            if (info?.zip) {
                infozip = info.zip;
            } else if (info?.zipCode) {
                infozip = info.zipCode;
            }

            if (info?.id) {
                setCustomerId(info?.id);
            }
            setCustomerName(infoname);
            setCustomerNumber(infocustomerNumber);
            setFein(infofeinNumber);
            setCountry(infocountry);
            if (infocountry) {
                dispatch(getStateByCountry(infocountry));
            }
            setState(infostate);

            setContactName(infocontactName);
            setPhoneNumber(infophoneNumber);
            setEmailAddress(infoemailAddress);
            setFaxNumber(infofaxNumber);
            setAlternateId(infoalternateId);
            setAddressLine1(infoaddressLine1);
            setAddressLine2(infoaddressLine2);
            if (infoaddressLine2) {
                showAddressLine2(true);
            }
            setCity(infocity);
            setZipCode(infozip);
        }
    }, [dispatch, props.customerInfo, props.isVendor]);

    useEffect(() => {
        if (country) {
            dispatch(getStateByCountry(country));
        } else if (defaultCountryId) {
            dispatch(getStateByCountry(defaultCountryId));
        }
        if (customer.addressLine2) {
            showAddressLine2(true);
        }
    }, [customer, dispatch, location.state, country, defaultCountryId]);

    useEffect(() => {
        if (initialState?.shipFromAddressCountry) {
            const fromCountry = countries.find(
                x => x.initials === initialState.shipFromAddressCountry
            );
            if (fromCountry === undefined || fromCountry === null) {
                dispatch(selectShipFromStatesByCountryName(initialState.shipFromAddressCountry));
            } else {
                setShipFromCountry(fromCountry?.name);
                dispatch(selectShipFromStatesByCountryName(fromCountry?.name));
            }
        }
        if (initialState?.shipToAddressCountry) {
            const toCountry = countries.find(x => x.initials === initialState.shipToAddressCountry);
            if (toCountry === undefined || toCountry === null) {
                dispatch(selectShipToStatesByCountryName(initialState?.shipToAddressCountry));
            } else {
                setShipToCountry(toCountry?.name);
                dispatch(selectShipToStatesByCountryName(toCountry?.name));
            }
        }
    }, [initialState?.shipFromAddressCountry, initialState?.shipToAddressCountry, countries]);

    useEffect(() => {
        const fromState = ShipFromStates?.find(
            x => x.initials === initialState?.shipFromAddressState
        );
        if (fromState === undefined || fromState === null) {
            setShipFromState(initialState?.shipFromAddressState);
        } else {
            setShipFromState(fromState.name);
        }

        const toState = ShipToStates.find(x => x.initials === initialState?.shipToAddressState);
        if (toState === undefined || toState === null) {
            setShipToState(initialState?.shipToAddressState);
        } else {
            setShipToState(toState.name);
        }
    }, [ShipFromStates, ShipToStates]);

    useEffect(() => {
        if (entityType === "vendor" && isEligibleUserVendorAdditionalInfo) {
            dispatch(getItemsAsync());
            dispatch(getCostCentersAsync());
        }
        return () => {
            dispatch(setItems([]));
            dispatch(setCostCenters([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (entityType === "vendor" && isEligibleUserVendorAdditionalInfo) {
            dispatch(getLocationAsync());
            if (initialState?.shipFromLocationId || initialState?.shipFromLocationCode) {
                setSelectedFromLocationField("shipFromLocationId");
            } else if (customer?.vendorAdditionalInfos) {
                setSelectedFromLocationField("shipFromLocationAddress");
            }
            if (initialState?.shipToLocationId || initialState?.shipToLocationCode) {
                setSelectedToLocationField("shipToLocationId");
            } else if (customer?.vendorAdditionalInfos) {
                setSelectedToLocationField("shipToLocationAddress");
            }

            if (
                initialState.defaultTaxExemption !== null &&
                initialState.defaultTaxExemption?.trim()?.length > 0
            ) {
                setSelectedVendorStatus("defaultExemptionNo");
            }
            if (initialState.isAccrual) {
                setSelectedVendorStatus("accrual");
            }
            if (initialState.isTaxOnVendor) {
                setSelectedVendorStatus("taxOnVendor");
            }

            if (initialState.isTrustedVendor) {
                setSelectedVendorStatus("trustedVendor");
            }
        }
        return () => {
            dispatch(setLocations([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props?.setFormUpdated) props.setFormUpdated(formUpdated);
    }, [formUpdated]); // eslint-disable-line

    useEffect(() => {
        if (props?.setshowSave) {
            props?.setshowSave(false);
        }
        if (props?.showSave) {
            props?.setshowSave(true);
        }
        if (customerUpdated && props?.lastIndex) {
            props?.setshowSave(true);
        }
    }, [customerUpdated, props?.lastIndex, props?.showSave]); // eslint-disable-line

    useEffect(() => {
        if (props.setSubmitting) props.setSubmitting(submitting);
    }, [submitting]); // eslint-disable-line

    const checkValidation = () => {
        let valid = true;
        if (emailAddress && !validateEmail(emailAddress)) {
            setEmailError("Please enter a valid email address");
            valid = false;
        } else {
            setEmailError("");
        }
        if (!customerNumber || customerNumber.trim()?.length <= 0) {
            setCustomerNumberError("error");
            valid = false;
        }
        if (!customerName || customerName.trim()?.length <= 0) {
            setCustomerNameError("error");
            valid = false;
        }
        if (!country) {
            setCountryError("error");
            valid = false;
        }
        if (!addressLine1 || addressLine1.trim()?.length <= 0) {
            setAddressLine1Error("Enter an address");
            valid = false;
        } else if (addressLine1?.length > 50) {
            setAddressLine1Error("The address can only contain 50 characters");
            valid = false;
        }

        if (!city || city.trim()?.length <= 0) {
            setCityError("error");
            valid = false;
        }
        if (states?.length > 0 && !state) {
            setStateError("error");
            valid = false;
        }
        if (!zipCode || zipCode.trim()?.length <= 0) {
            setZipCodeError("Enter a zip code");
            valid = false;
        }
        if (zipCode) {
            if (country === "United States" && (zipCode.length < 5 || zipCode.length > 10)) {
                setZipCodeError("Zip length must be between 5 and 10 characters");
                valid = false;
            }
            if (country === "Canada" && (zipCode.length < 6 || zipCode.length > 10)) {
                setZipCodeError("Zip length must be between 6 and 10 characters");
                valid = false;
            }
        }

        if (!valid && !document.querySelector("s-toasts").getToast("validation-warning")) {
            showToast(
                "warning",
                "Please correct the information highlighted in red above.",
                "validation-warning"
            );
        }
        return valid;
    };

    const handleChangeInput = (setter, errorSetter) => {
        return event => {
            if (event.target.value && event.target.className === "error") {
                errorSetter("");
            }
            setter(event.target.value);
            setFormUpdated(true);
            setCustomerUpdated(true);
            hideAllToast();
        };
    };
    const updatedCustomerData = () => {
        const customerid = parseInt(id, 10);
        const customerData = {
            id: customerid,
            customerNumber: customerNumber?.trim(),
            name: customerName?.trim(),
            customerName: customerName?.trim(),
            alternateId: alternateId?.trim(),
            addressLine1: addressLine1?.trim(),
            addressLine2: addressLine2?.trim(),
            city: city?.trim(),
            zip: zipCode?.trim(),
            zipCode: zipCode?.trim(),
            contactName: contactName?.trim(),
            phoneNumber: phoneNumber?.trim(),
            emailAddress: emailAddress?.trim(),
            faxNumber: faxNumber?.trim(),
            customerUpdated,
            feinNumber: fein?.trim(),
            fein: fein?.trim(),
            country: { id: country || defaultCountryId, stateCount: states?.length },
            isVendor: vendorCheck,
            docName,
        };

        if (state) {
            customerData.state = { id: state };
        }
        return customerData;
    };

    useEffect(() => {
        if (props?.saveClicked) {
            if (checkValidation()) {
                props?.saveStep(updatedCustomerData());
            }
        }
    }, [props?.saveClicked]); // eslint-disable-line

    useEffect(() => {
        if (props?.nextClicked) {
            if (checkValidation()) {
                props?.nextStep(updatedCustomerData());
            }
        }
    }, [props?.nextClicked]); // eslint-disable-line

    useEffect(() => {
        if (props?.backClicked) {
            props.backStep(updatedCustomerData());
            setEmailError("");
            setCustomerNumberError("");
            setCustomerNameError("");
            setCountryError("");
            setAddressLine1Error("");
            setCityError("");
            setStateError("");
            setZipCodeError("");
        }
    }, [props?.backClicked]); // eslint-disable-line

    const handleCountryChange = value => {
        setCountry(value);
        setState(null);
        setFormUpdated(true);
        setCustomerUpdated(true);
        hideAllToast();
        setCountryError("valid");
        dispatch(getStateByCountry(value));
    };
    const handleCountryRemove = () => {
        setCountry("");
        setState(null);
        setCustomerUpdated(true);
        setFormUpdated(true);
        hideAllToast();
        setCountryError("valid");
    };

    const handleShipFromLocationChange = el => {
        setShipFromLocationId(el.detail.item.value);
        setShipFromLocationCode(el.detail.item.label);
        setFormUpdated(true);
        setCustomerUpdated(true);
    };
    const handleShipFromLocationRemove = () => {
        setShipFromLocationId(0);
        setShipFromLocationCode("");
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleItemCodeChange = el => {
        setItemCodeId(el.detail.item.value);
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleItemCodeRemove = () => {
        setItemCodeId(0);
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleCostCenterChange = el => {
        setCostCenter(el.detail.item.value);
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleCostCenterRemove = () => {
        setCostCenter(0);
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleShipToLocationChange = el => {
        setShipToLocationId(el.detail.item.value);
        setShipToLocationCode(el.detail.item.label);
        setFormUpdated(true);
        setCustomerUpdated(true);
    };
    const handleShipToLocationRemove = () => {
        setShipToLocationId(0);
        setShipToLocationCode("");
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleStateRemove = () => {
        setState("");
        setFormUpdated(true);
        setCustomerUpdated(true);
        hideAllToast();
        setStateError("valid");
    };

    const handleStateChange = value => {
        setState(value.value);
        setFormUpdated(true);
        setCustomerUpdated(true);
        hideAllToast();
        setStateError("valid");
    };

    const handleShipFromCountryChange = value => {
        const countryValue = getNameById(shipFromCountries, value);
        setShipFromCountry(countryValue?.label);
        dispatch(selectShipFromStatesByCountryName(countryValue?.label));
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleShipFromCountryRemove = () => {
        setShipFromCountry("");
        setShipFromState("");
        setCustomerUpdated(true);
        setFormUpdated(true);
        hideAllToast();
    };

    const handleShipToCountryChange = value => {
        const countryValue = getNameById(shipFromCountries, value);
        setShipToCountry(countryValue?.label);
        dispatch(selectShipToStatesByCountryName(countryValue?.label));
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleShipToCountryRemove = () => {
        setShipToCountry("");
        setShipToState("");
        setCustomerUpdated(true);
        setFormUpdated(true);
        hideAllToast();
    };

    const handleShipFromStateChange = element => {
        setShipFromState(element?.detail?.item?.label);
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleShipFromStateRemove = () => {
        setShipFromState("");
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleShipToStateChange = element => {
        setShipToState(element?.detail?.item?.label);
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const handleShipToStateRemove = () => {
        setShipToState("");
        setFormUpdated(true);
        setCustomerUpdated(true);
    };

    const postCustomerAPI = customerData => {
        return axios.post(buildApiV3Url(`customers`), customerData, {
            withCredentials: true,
        });
    };

    const putCustomerAPI = async customerData => {
        return axios.put(buildApiV3Url(`customers/${customer.id}`), customerData, {
            withCredentials: true,
        });
    };

    const updateCustomLabelPostData = useCallback(async (e, add, selectRef) => {
        let value = null;
        const allSelectedValues = await selectRef?.current?.getValue().then(x => {
            return x;
        });
        setIsLabelUpdated(true);
        if (e.detail.isDeselectAll) {
            setLabelPostData([]);
        } else if (add) {
            value = allSelectedValues;
        } else {
            value = allSelectedValues?.filter(item => {
                return item !== e.detail.item.value;
            });
        }
        setLabelPostData(value);
        setFormUpdated(true);
    }, []);
    const customerLabelAPI = customerID => {
        axios.put(
            buildApiV3Url(`customers/${customerID}/customer-labels`),
            { customerLabelIds: labelPostData },
            {
                withCredentials: true,
            }
        );
    };

    const addCustomersExposureZones = async (customerId, documentDescription, stateId) => {
        const requestBody = {
            documentTypeName: documentDescription,
            outgoing: vendorCheck,
        };
        return axios
            .post(
                buildApiV3Url(`customers/${customerId}/ship-to-zones/state/${stateId}`),
                requestBody,
                {
                    withCredentials: true,
                }
            )
            .catch(error => {
                return error;
            });
    };

    const handleSubmitForm = async event => {
        event.preventDefault();
        if (checkValidation()) {
            setSubmitting(true);
            const isShipFromUseAddress =
                selectedFromLocationField === "shipFromLocationId" && shipFromLocationId > 0;

            const isShipToUseAddress =
                selectedToLocationField === "shipToLocationId" && shipToLocationId > 0;
            const vendorAdditionalData = {
                costCenterId: costCenter,
                costCenterCode,
                itemCodeId,
                shipFromLocationId:
                    selectedFromLocationField === "shipFromLocationId" ? shipFromLocationId : 0,
                shipFromLocationCode:
                    selectedFromLocationField === "shipFromLocationId" ? shipFromLocationCode : "",
                shipFromAddressLine: isShipFromUseAddress ? "" : shipFromAddressLine1,
                shipFromAddressCity: isShipFromUseAddress ? "" : shipFromCity,
                shipFromAddressState: isShipFromUseAddress ? "" : shipFromState,
                shipFromAddressZip: isShipFromUseAddress ? "" : shipFromZipCode,
                shipFromAddressCountry: isShipFromUseAddress ? "" : shipFromCountry,
                shipToLocationId:
                    selectedToLocationField === "shipToLocationId" ? shipToLocationId : 0,
                shipToLocationCode:
                    selectedToLocationField === "shipToLocationId" ? shipToLocationCode : "",

                shipToAddressLine: isShipToUseAddress ? "" : shipToAddressLine1,
                shipToAddressCity: isShipToUseAddress ? "" : shipToCity,
                shipToAddressState: isShipToUseAddress ? "" : shipToState,
                shipToAddressZip: isShipToUseAddress ? "" : shipToZipCode,
                shipToAddressCountry: isShipToUseAddress ? "" : shipToCountry,
                isTrustedVendor: selectedVendorStatus === "trustedVendor",
                isAccrual: selectedVendorStatus === "accrual",
                defaultTaxExemption: selectedVendorStatus === "defaultExemptionNo" ? "L" : null,
                isTaxOnVendor: selectedVendorStatus === "taxOnVendor",
            };

            const customerData = {
                customerNumber: customerNumber?.trim(),
                name: customerName?.trim(),
                alternateId: alternateId?.trim(),
                addressLine1: addressLine1?.trim(),
                addressLine2: addressLine2?.trim(),
                city: city?.trim(),
                zip: zipCode?.trim(),
                feinNumber: fein?.trim(),
                contactName: contactName?.trim(),
                phoneNumber: phoneNumber?.trim(),
                emailAddress: emailAddress?.trim(),
                faxNumber: faxNumber?.trim(),
                isBillTo: initialState?.isBillTo,
                isShipTo: initialState?.isShipTo,
                isVendor: !!vendorCheck,
                vendorAdditionalInfos:
                    isEligibleUserVendorAdditionalInfo && entityType === "vendor"
                        ? vendorAdditionalData
                        : {},
            };
            if (customer.id) {
                customerData.country = { id: country || defaultCountryId };
                if (state) {
                    customerData.state = { id: state };
                }

                const updatedCustomer = await putCustomerAPI(customerData);
                if (updatedCustomer.data) {
                    const error = errorResponse(updatedCustomer);
                    if (error?.code === "AvataxIntegrationError") {
                        setSubmitting(false);
                        setLogEntry(error.detail);
                        setIsSyncErrorDialogOpen(true);
                    } else if (updatedCustomer.data.success) {
                        const customersData = updatedCustomer.data.returnValue;
                        if (customersData) {
                            dispatch(setCustomer(customersData));
                            await dispatch(customFieldsAPI(customer.id));
                            if (isLabelUpdated) await customerLabelAPI(customer.id);
                            setSubmitting(false);
                            showToast(
                                "success",
                                `Successfully updated ${entityType}.`,
                                "customer-success",
                                ...toastPersistenceAndMessage
                            );
                            if (entityType === "vendor") {
                                dispatch(getVendorMissingCertRegion(customer.id));
                            } else {
                                dispatch(getCustomerMissingCertRegion(customer.id));
                            }
                            setFormUpdated(false);
                        } else {
                            setSubmitting(false);
                            setFormUpdated(false);
                        }
                    } else if (!updatedCustomer.data.success) {
                        setSubmitting(false);
                        showToast("error", updatedCustomer.data.message, "customer-success");
                    }
                } else {
                    setSubmitting(false);
                    setFormUpdated(false);
                }
            } else {
                customerData.country = { id: country || defaultCountryId };
                if (state) {
                    customerData.state = { id: state };
                }
                const returnData = await postCustomerAPI(customerData);
                if (returnData.data) {
                    if (returnData.data.success) {
                        const customersData = returnData.data.returnValue;
                        if (props?.handleAddCustomer) props.handleAddCustomer([customersData]);
                        if (customersData) {
                            if (settings?.autoCustomerTaxRegion === true) {
                                const response = await addCustomersExposureZones(
                                    customersData.id,
                                    docName,
                                    customerData.state.id
                                );

                                if (!response.data) {
                                    setFormUpdated(false);
                                    if (isDisplayedAsDialog) {
                                        showToast(
                                            "warning",
                                            `Successfully added ${entityType} but could not add tax regions. See ${entityType} details.`,
                                            "customer-success"
                                        );
                                    } else {
                                        showToast(
                                            "warning",
                                            `Successfully added ${entityType} but could not add tax regions. See ${entityType} details.`,
                                            "customer-success",
                                            ...toastPersistenceAndMessage
                                        );
                                    }
                                } else {
                                    customersData.isShipTo = response.data.isShipTo;
                                    customersData.isBillTo = response.data.isBillTo;
                                }
                            }
                        }
                        await dispatch(customFieldsAPI(customersData.id));
                        await customerLabelAPI(customersData.id);
                        setSubmitting(false);
                        if (event.target.id === "save-and-add") {
                            dispatch(
                                setRedirectURL(
                                    urlToPageMap.customerDetail.replace(":id?", customersData?.id)
                                )
                            );

                            navigate(urlToPageMap.review, {
                                state: { customer: customersData },
                            });
                        } else {
                            setFormUpdated(false);
                            if (isDisplayedAsDialog) {
                                showToast(
                                    "success",
                                    `Successfully added ${entityType}.`,
                                    "customer-success"
                                );
                                props?.setCustomerDialogOpen && props.setCustomerDialogOpen(false); // eslint-disable-line
                            } else {
                                navigate(`/${entityType}/${customersData.id}`);
                                showToast(
                                    "success",
                                    `The ${entityType} has been saved.`,
                                    "customer-success",
                                    ...toastPersistenceAndMessage
                                );
                            }
                        }
                    } else if (!returnData.data.success) {
                        setSubmitting(false);
                        showToast("error", returnData.data.message, "customer-success");
                    }
                } else {
                    setSubmitting(false);
                    showToast(
                        "error",
                        `Could not create ${entityType}. Try again later.`,
                        "customer-success"
                    );
                    props?.setCustomerDialogOpen && props.setCustomerDialogOpen(false); // eslint-disable-line
                }
            }
        }
    };

    const deleteCustomer = async () => {
        const hasCertificate = await axios.get(
            buildApiV3Url(`customers/${customer.id}/has-certificate`),
            {
                withCredentials: true,
            }
        );
        setIsDeleteCustomerDialogOpen(true);
        if (hasCertificate.data) {
            setIsCustomerWithCertificate(true);
        } else {
            setIsCustomerWithCertificate(false);
        }
    };

    const handleCancel = () => {
        if (props?.navigateTo) {
            if (isAvaTaxAp) {
                navigate(`/search/vendors`);
            } else {
                props.navigateTo("exemptions");
            }
        } else {
            window.location.reload();
        }
    };
    let PageHeader = <SRow className="margin-top-xl" />;
    if (!customer.customerNumber && !isDisplayedAsDialog)
        PageHeader = <h1 className="margin-bottom-xl">Add a {entityType} </h1>;
    else if (isDisplayedAsDialog) PageHeader = null;

    if (isLoading) {
        CustomerDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        CustomerDOM = (
            <div>
                <Helmet>
                    <title>{customerName ? titleCustomer : title}</title>
                </Helmet>
                {PageHeader}
                <form onSubmit={handleSubmitForm}>
                    <SRow>
                        <SCol span={isDisplayedAsDialog ? "4" : "2"}>
                            <label htmlFor="customerName" className="required">
                                {upperFirst(entityType)} name
                            </label>
                            <input
                                id="customerName"
                                name="customerName"
                                type="text"
                                className={customerNameError ? errorTag : noErrorTag}
                                onChange={handleChangeInput(setCustomerName, setCustomerNameError)}
                                value={customerName}
                            />

                            {customerNameError ? (
                                <ValidationError
                                    errorMsg={
                                        customerNameError !== "error" ? customerNameError : null
                                    }
                                    fieldName={`${entityType} name`}
                                />
                            ) : null}
                        </SCol>
                        <SCol span={isDisplayedAsDialog ? "4" : "2"}>
                            <label htmlFor="customer_number" className="required">
                                {upperFirst(entityType)} code
                            </label>
                            <input
                                id="customer_number"
                                name="customerNumber"
                                type="text"
                                className={customerNumberError ? errorTag : noErrorTag}
                                onChange={handleChangeInput(
                                    setCustomerNumber,
                                    setCustomerNumberError
                                )}
                                value={customerNumber}
                            />

                            {customerNumberError ? (
                                <ValidationError
                                    errorMsg={
                                        customerNumberError !== "error" ? customerNumberError : null
                                    }
                                    fieldName={`${entityType} code`}
                                />
                            ) : null}
                        </SCol>
                        <SCol span={isDisplayedAsDialog ? "4" : "2"}>
                            <label htmlFor="taxpayer_id">Taxpayer ID</label>
                            <input
                                id="taxpayer_id"
                                className="fs-exclude"
                                name="taxPayerId"
                                type="text"
                                onChange={handleChangeInput(setFein)}
                                value={fein}
                            />
                        </SCol>
                        <SCol span={isDisplayedAsDialog ? "4" : "2"}>
                            <label htmlFor="alternate_id">Alternate ID</label>
                            <input
                                id="alternate_id"
                                name="alternateId"
                                type="text"
                                className={alternateIdError ? errorTag : noErrorTag}
                                onChange={handleChangeInput(setAlternateId, setAlternateIdError)}
                                value={alternateId}
                            />
                            {alternateIdError ? (
                                <ValidationError errorMsg={alternateIdError} />
                            ) : null}
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span={isDisplayedAsDialog ? "4" : "3"}>
                            <h3>Primary business address</h3>

                            <SelectCountry
                                onChange={handleCountryChange}
                                onRemove={handleCountryRemove}
                                value={country}
                                all
                                required
                                countryError={countryError}
                            />

                            {countryError ? <ValidationError fieldName="country" /> : null}

                            <label htmlFor="addressLine1" className="required">
                                Address
                            </label>
                            <input
                                name="addressLine1"
                                id="addressLine1"
                                type="text"
                                className={addressLine1Error ? errorTag : noErrorTag}
                                onChange={handleChangeInput(setAddressLine1, setAddressLine1Error)}
                                value={addressLine1}
                                aria-required="true"
                                // maxLength="50"
                            />

                            {addressLine1Error ? (
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span className="top-xs">{addressLine1Error}</span>
                                </div>
                            ) : null}

                            {!showingAddressLine2 ? (
                                <div className="margin-top-xs">
                                    <button className="link" onClick={() => showAddressLine2(true)}>
                                        <SIcon
                                            name="plus"
                                            className="custom-top-icon"
                                            aria-hidden="true"
                                        />
                                        <span className="margin-left-xs">Address line</span>
                                    </button>
                                </div>
                            ) : null}
                            {showingAddressLine2 ? (
                                <div className="margin-top-xs">
                                    <label htmlFor="addressLine2">Address Line 2</label>
                                    <input
                                        name="addressLine2"
                                        id="addressLine2"
                                        type="text"
                                        className={addressLine2Error ? errorTag : noErrorTag}
                                        onChange={handleChangeInput(
                                            setAddressLine2,
                                            setAddressLine2Error
                                        )}
                                        value={addressLine2}
                                    />
                                    {addressLine2Error ? (
                                        <ValidationError errorMsg={addressLine2Error} />
                                    ) : null}
                                </div>
                            ) : null}
                            <label htmlFor="city" className="required">
                                City
                            </label>
                            <input
                                name="city"
                                id="city"
                                type="text"
                                className={cityError ? errorTag : noErrorTag}
                                onChange={handleChangeInput(setCity, setCityError)}
                                value={city}
                                aria-required="true"
                            />

                            {cityError ? (
                                <ValidationError
                                    errorMsg={cityError !== "error" ? cityError : null}
                                    fieldName="city"
                                />
                            ) : null}

                            <SelectState
                                onChange={handleStateChange}
                                onRemove={handleStateRemove}
                                value={state}
                                required
                                stateError={stateError || noErrorTag}
                            />

                            {states.length > 0 && stateError === "error" ? (
                                <ValidationError fieldName="region" />
                            ) : null}

                            <label htmlFor="zipCode" className="required">
                                Zip
                            </label>
                            <input
                                name="zipCode"
                                id="zipCode"
                                type="text"
                                className={zipCodeError ? errorTag : noErrorTag}
                                onChange={handleChangeInput(setZipCode, setZipCodeError)}
                                value={zipCode}
                                aria-required="true"
                            />

                            {zipCodeError ? (
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span className="top-xs">{zipCodeError}</span>
                                </div>
                            ) : null}
                        </SCol>
                        <SCol span={isDisplayedAsDialog ? "4" : "3"}>
                            <h3>Contact information</h3>

                            <label htmlFor="contactName">Name of contact</label>
                            <input
                                name="contactName"
                                id="contactName"
                                type="text"
                                onChange={handleChangeInput(setContactName, setContactNameError)}
                                className={contactNameError ? errorTag : noErrorTag}
                                value={contactName}
                            />
                            {contactNameError ? (
                                <ValidationError errorMsg={contactNameError} />
                            ) : null}

                            <label htmlFor="phoneNumber">Phone number</label>
                            <input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                onChange={handleChangeInput(setPhoneNumber, setPhoneNumberError)}
                                className={phoneNumberError ? errorTag : noErrorTag}
                                value={phoneNumber}
                            />
                            {phoneNumberError ? (
                                <ValidationError errorMsg={phoneNumberError} />
                            ) : null}

                            <label htmlFor="email">Email</label>
                            <input
                                name="email"
                                id="email"
                                type="text"
                                className={emailError !== "" ? errorTag : noErrorTag}
                                onChange={handleChangeInput(setEmailAddress, setEmailError)}
                                value={emailAddress}
                            />
                            {emailError ? (
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span className="top-xs" name="invalid-email">
                                        {emailError}
                                    </span>
                                </div>
                            ) : null}

                            <label htmlFor="faxNumber">Fax</label>
                            <input
                                name="faxNumber"
                                id="faxNumber"
                                type="text"
                                onChange={handleChangeInput(setFaxNumber, setFaxNumberError)}
                                className={faxNumberError ? errorTag : noErrorTag}
                                value={faxNumber}
                            />
                            {faxNumberError ? <ValidationError errorMsg={faxNumberError} /> : null}
                        </SCol>
                        <SCol span={isDisplayedAsDialog ? "4" : "3"}>
                            <FeatureToggler category="customers" id="customerLabels">
                                <h3>Additional customer info</h3>
                            </FeatureToggler>
                            <FeatureToggler category="customers" id="customFields">
                                <CustomerCustomField
                                    customerID={id > 0 ? id : customer.id}
                                    ref={customerCustomFieldRef}
                                />
                            </FeatureToggler>

                            <FeatureToggler category="customers" id="customerLabels">
                                <CustomerLabel
                                    updateCustomLabelPostData={updateCustomLabelPostData}
                                    customerID={id > 0 ? id : customer.id}
                                />
                            </FeatureToggler>
                        </SCol>
                    </SRow>
                    {entityType === "vendor" ? (
                        <FeatureToggler category="vendors" id="vendorAdditionalInfo">
                            <SRow>
                                <SCol span="12">
                                    <h3>Default transaction values</h3>
                                    <label>
                                        On transactions that include this vendor, fill empty fields
                                        with these default values.
                                    </label>
                                    <br />
                                    <label className="text-sm-strong">Ship from location</label>
                                    <fieldset role="radiogroup">
                                        <input
                                            name="shipFromLocationId"
                                            id="shipFromLocationId"
                                            type="radio"
                                            value="shipFromLocationId"
                                            onChange={e => handleRadioChange(e)}
                                            checked={
                                                selectedFromLocationField === "shipFromLocationId"
                                            }
                                        />
                                        <label
                                            htmlFor="shipFromLocationId"
                                            className="margin-top-none">
                                            Use this location code
                                        </label>

                                        {selectedFromLocationField === "shipFromLocationId" ? (
                                            <SRow>
                                                <SCol span="2" className="margin-left-lg">
                                                    <label
                                                        id="lbl-shipFromLocation"
                                                        htmlFor="shipFromLocationId">
                                                        Location Code
                                                    </label>
                                                    <SSelect
                                                        name="shipFromLocationId"
                                                        inputId="shipFromLocationId"
                                                        onS-select={e =>
                                                            handleShipFromLocationChange(e)
                                                        }
                                                        onS-deselect={handleShipFromLocationRemove}
                                                        selectionOptional
                                                        optionsList={getLocationOptionList(
                                                            shipFromLocationId,
                                                            shipFromLocationCode
                                                        )}
                                                        loading={isLocationsLoading}
                                                    />
                                                </SCol>
                                            </SRow>
                                        ) : null}

                                        <br />
                                        <input
                                            name="shipFromLocationAddress"
                                            id="shipFromLocationAddress"
                                            type="radio"
                                            value="shipFromLocationAddress"
                                            onChange={e => handleRadioChange(e)}
                                            checked={
                                                selectedFromLocationField ===
                                                "shipFromLocationAddress"
                                            }
                                        />
                                        <label htmlFor="shipFromLocationAddress">
                                            Use this address
                                        </label>
                                        {selectedFromLocationField === "shipFromLocationAddress" ? (
                                            <SRow>
                                                <SCol
                                                    span={isDisplayedAsDialog ? "4" : "3"}
                                                    className="margin-top-sm margin-left-lg">
                                                    <SelectCountry
                                                        onChange={handleShipFromCountryChange}
                                                        onRemove={handleShipFromCountryRemove}
                                                        all
                                                        value={shipFromCountry}
                                                        required={false}
                                                    />

                                                    <label
                                                        id="lbl-address-line1"
                                                        htmlFor="shipFromAddressLine1">
                                                        Address
                                                    </label>
                                                    <input
                                                        id="shipFromAddressLine1"
                                                        name="shipFromAddressLine1"
                                                        type="text"
                                                        onChange={handleChangeInput(
                                                            setShipFromAddressLine1
                                                        )}
                                                        value={shipFromAddressLine1}
                                                    />

                                                    <label htmlFor="shipFromCity">City</label>
                                                    <input
                                                        name="shipFromCity"
                                                        id="shipFromCity"
                                                        type="text"
                                                        onChange={handleChangeInput(
                                                            setShipFromCity
                                                        )}
                                                        value={shipFromCity}
                                                    />
                                                    <label
                                                        id="lbl-shipFromState"
                                                        htmlFor="shipFromState">
                                                        State
                                                    </label>

                                                    <SSelect
                                                        name="shipFromState"
                                                        inputId="shipFromState"
                                                        selectionOptional
                                                        onS-select={e =>
                                                            handleShipFromStateChange(e)
                                                        }
                                                        onS-deselect={handleShipFromStateRemove}
                                                        optionsList={getShipFromStateOptionList(
                                                            shipFromState
                                                        )}
                                                        value={shipFromState}
                                                        loading={isLoadingShipFromStates}
                                                    />

                                                    <label htmlFor="shipFromZipCode">Zip</label>
                                                    <input
                                                        name="shipFromZipCode"
                                                        id="shipFromZipCode"
                                                        type="text"
                                                        onChange={handleChangeInput(
                                                            setShipFromZipCode
                                                        )}
                                                        value={shipFromZipCode}
                                                    />
                                                </SCol>
                                            </SRow>
                                        ) : null}
                                    </fieldset>

                                    <label className="text-sm-strong margin-top-lg">
                                        Ship to location
                                    </label>
                                    <fieldset role="radiogroup">
                                        <input
                                            name="shipToLocationId"
                                            id="shipToLocationId"
                                            type="radio"
                                            value="shipToLocationId"
                                            onChange={e => handleRadioChange(e)}
                                            checked={selectedToLocationField === "shipToLocationId"}
                                        />
                                        <label
                                            htmlFor="shipToLocationId"
                                            className="margin-top-none">
                                            Use this location code
                                        </label>

                                        {selectedToLocationField === "shipToLocationId" ? (
                                            <SRow>
                                                <SCol span="2" className="margin-left-lg">
                                                    <label
                                                        id="lbl-shipToLocation"
                                                        htmlFor="shipToLocationId">
                                                        Location Code
                                                    </label>
                                                    <SSelect
                                                        name="shipToLocationId"
                                                        inputId="shipToLocationId"
                                                        onS-select={e =>
                                                            handleShipToLocationChange(e)
                                                        }
                                                        onS-deselect={handleShipToLocationRemove}
                                                        selectionOptional
                                                        optionsList={getLocationOptionList(
                                                            shipToLocationId,
                                                            shipToLocationCode
                                                        )}
                                                        loading={isLocationsLoading}
                                                    />
                                                </SCol>
                                            </SRow>
                                        ) : null}
                                        <br />
                                        <input
                                            name="shipToLocationAddress"
                                            type="radio"
                                            id="shipToLocationAddress"
                                            value="shipToLocationAddress"
                                            onChange={e => handleRadioChange(e)}
                                            checked={
                                                selectedToLocationField === "shipToLocationAddress"
                                            }
                                        />
                                        <label htmlFor="shipToLocationAddress">
                                            Use this address
                                        </label>
                                        {selectedToLocationField === "shipToLocationAddress" ? (
                                            <SRow>
                                                <SCol
                                                    span={isDisplayedAsDialog ? "4" : "3"}
                                                    className="margin-top-sm margin-left-lg">
                                                    <SelectCountry
                                                        onChange={handleShipToCountryChange}
                                                        onRemove={handleShipToCountryRemove}
                                                        all
                                                        value={shipToCountry}
                                                        required={false}
                                                    />

                                                    <label
                                                        id="lbl-shipToAddressLine1"
                                                        htmlFor="shipToAddressLine1">
                                                        Address
                                                    </label>
                                                    <input
                                                        name="shipToAddressLine1"
                                                        id="shipToAddressLine1"
                                                        type="text"
                                                        onChange={handleChangeInput(
                                                            setShipToAddressLine1
                                                        )}
                                                        value={shipToAddressLine1}
                                                    />

                                                    <label htmlFor="shipToCity">City</label>
                                                    <input
                                                        name="shipToCity"
                                                        id="shipToCity"
                                                        type="text"
                                                        onChange={handleChangeInput(setShipToCity)}
                                                        value={shipToCity}
                                                    />
                                                    <label
                                                        id="lbl-shipToState"
                                                        htmlFor="shipToState">
                                                        State
                                                    </label>

                                                    <SSelect
                                                        name="shipToState"
                                                        inputId="shipToState"
                                                        selectionOptional
                                                        onS-select={e => handleShipToStateChange(e)}
                                                        onS-deselect={handleShipToStateRemove}
                                                        optionsList={getShipToStateOptionList(
                                                            shipToState
                                                        )}
                                                        value={shipToState}
                                                        loading={isLoadingShipToStates}
                                                    />

                                                    <label htmlFor="shipToZipCode">Zip</label>
                                                    <input
                                                        name="shipFromZipCode"
                                                        type="text"
                                                        onChange={handleChangeInput(
                                                            setShipToZipCode
                                                        )}
                                                        value={shipToZipCode}
                                                    />
                                                </SCol>
                                            </SRow>
                                        ) : null}
                                    </fieldset>
                                </SCol>
                            </SRow>
                            <SRow className="margin-top-sm">
                                <SCol span={isDisplayedAsDialog ? "4" : "3"}>
                                    <label id="costCenter" htmlFor="costCenter">
                                        Cost center
                                    </label>
                                    <SSelect
                                        name="costCenter"
                                        inputId="costCenter"
                                        onS-select={e => handleCostCenterChange(e)}
                                        onS-deselect={handleCostCenterRemove}
                                        selectionOptional
                                        optionsList={getCostCenterOptionList(
                                            costCenter,
                                            costCenterCode
                                        )}
                                        loading={isCostCenterLoading}
                                    />
                                </SCol>
                            </SRow>
                            <SRow className="margin-top-sm">
                                <SCol span={isDisplayedAsDialog ? "4" : "3"}>
                                    <label id="lbl-item" htmlFor="item">
                                        Item code
                                    </label>
                                    <SSelect
                                        name="item"
                                        inputId="item"
                                        onS-select={e => handleItemCodeChange(e)}
                                        onS-deselect={handleItemCodeRemove}
                                        selectionOptional
                                        optionsList={getItemOptionList(itemCodeId, itemCode)}
                                        loading={isItemsLoading}
                                    />
                                </SCol>
                            </SRow>

                            <SRow>
                                <SCol span="12">
                                    <h3 className="margin-bottom-none">Vendor status</h3>
                                    <fieldset role="radiogroup">
                                        <input
                                            name="taxOnVendor"
                                            id="taxOnVendor"
                                            type="radio"
                                            value="taxOnVendor"
                                            onChange={e => handleRadioChange(e)}
                                            checked={selectedVendorStatus === "taxOnVendor"}
                                        />
                                        <label htmlFor="taxOnVendor">
                                            Tax on this vendor&apos;s transactions are accrued after
                                            you review them.
                                        </label>
                                        <br />

                                        <input
                                            name="accrual"
                                            id="accrual"
                                            type="radio"
                                            value="accrual"
                                            onChange={e => handleRadioChange(e)}
                                            checked={selectedVendorStatus === "accrual"}
                                        />
                                        <label htmlFor="accrual">
                                            Accrue tax on this vendor&apos;s transactions without
                                            reviewing them first.
                                        </label>

                                        <br />
                                        <input
                                            name="trustedVendor"
                                            id="trustedVendor"
                                            type="radio"
                                            value="trustedVendor"
                                            onChange={e => handleRadioChange(e)}
                                            checked={selectedVendorStatus === "trustedVendor"}
                                        />
                                        <label htmlFor="trustedVendor">
                                            This vendor is trusted. Accept their tax calculations
                                            and don&apos;t calculate consumer use on their
                                            transactions.
                                        </label>
                                        <br />

                                        <input
                                            name="defaultExemptionNo"
                                            id="defaultExemptionNo"
                                            type="radio"
                                            value="defaultExemptionNo"
                                            onChange={e => handleRadioChange(e)}
                                            checked={selectedVendorStatus === "defaultExemptionNo"}
                                        />
                                        <label htmlFor="defaultExemptionNo">
                                            Purchases from this vendor are exempt from sales tax.
                                        </label>
                                    </fieldset>
                                </SCol>
                            </SRow>
                        </FeatureToggler>
                    ) : null}

                    {!isDisplayedAsDialog ? (
                        <hr className="margin-top-lg margin-bottom-lg" />
                    ) : null}
                    <SRow>
                        <SCol span="12" className={isDisplayedAsDialog ? "hidden" : ""}>
                            <button
                                id="save-customer"
                                type="submit"
                                className={saveCustomerButton}
                                disabled={
                                    !(formUpdated || isFormUpdated) ||
                                    (entityType === "customer"
                                        ? !isEligibleUserManageCustomerFlag
                                        : !isEligibleUserManageVendorFlag)
                                }
                                ref={ref}>
                                Save
                            </button>
                            {!customer.customerNumber && entityType === "customer" ? (
                                <button
                                    id="save-and-add"
                                    type="button"
                                    onClick={handleSubmitForm}
                                    disabled={
                                        !(formUpdated || isFormUpdated) ||
                                        !isEligibleUserManageCustomerFlag
                                    }
                                    className={addSaveCustomerButton}>
                                    Save and add certificate
                                </button>
                            ) : null}
                            <button className={cancelButton} onClick={handleCancel} type="button">
                                Cancel
                            </button>
                            {location.pathname.includes("/customer/") ||
                            location.pathname.includes("/vendor/") ? (
                                <button
                                    className="link font-bold margin-left-xl"
                                    disabled={
                                        entityType === "customer"
                                            ? !isEligibleUserManageCustomerFlag
                                            : !isEligibleUserManageVendorFlag
                                    }
                                    onClick={() => deleteCustomer()}
                                    type="button">
                                    Delete this {entityType}
                                </button>
                            ) : null}
                        </SCol>
                    </SRow>
                </form>
            </div>
        );
    }

    return (
        <React.Fragment>
            {CustomerDOM}
            <DeleteCustomerDialog
                isDeleteCustomerDialogOpen={isDeleteCustomerDialogOpen}
                isCustomerWithCertificate={isCustomerWithCertificate}
                setIsDeleteCustomerDialogOpen={setIsDeleteCustomerDialogOpen}
                setIsCustomerWithCertificate={setIsCustomerWithCertificate}
                customerId={customer.id}
                type="request"
                isVendor={vendorCheck}
            />
            <SyncErrorDialog
                isSyncErrorDialogOpen={isSyncErrorDialogOpen}
                setIsSyncErrorDialogOpen={setIsSyncErrorDialogOpen}
                logEntry={logEntry}
            />
        </React.Fragment>
    );
});

export default Customer;
