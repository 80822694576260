import { SCol, SLoader, SRow } from "@avalara/skylab-react";
import { isEmpty } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    fetchSignatureData,
    selectSetting,
    selectSignatureData,
    sendOutgoingStaticDocument,
    selectDocumentTypeInfo,
    fetchDocumentTypeInfoByName,
} from "../../../app/settingsSlice";
import { isEligibleUser } from "../../../shared/sessionUtility";
import { validateEmail } from "../../../shared/Utils";
import GenCert from "../../sharedComponents/GenCert";
import SelectVendorReasons from "./SelectVendorReasons";
import SelectVendorZone from "./SelectVendorZone";
import VendorRequestValidationError from "./vendorRequestValidationError";
import { selectCoverLetterPreview, selectDeliveryType } from "../../../app/coverLetterSlice";
import { fetchCustomerAPI, selectCustomer, selectLoading } from "../../../app/customerSlice";
import { selectData, setData } from "../../../app/exemptionMatrixSlice";
import { selectSession } from "../../../app/sessionSlice";
import SignatureError from "./SignatureError";
import featureFlag from "../../../featureToggler/featureFlag";
import SelectCoverLetter from "../../sharedComponents/SelectCoverLetter";
import OutgoingDeliveryType from "./OutgoingDeliveryType";
import toast from "../../../hooks/toast";
import { SalesAndUseTax } from "../../../shared/constants";

function VendorRequest() {
    const dispatch = useDispatch();
    const vendorReasonRef = useRef();
    const params = useParams();
    const navigate = useNavigate();
    const [showToast] = toast();
    const vendor = useSelector(selectCustomer, shallowEqual);
    const vendorNumber = vendor.customerNumber;
    const [exemptReason, setExemptReason] = useState("");
    const [exemptReasonId, setExemptReasonId] = useState("");
    const [checkGenCert, enableGenCert] = useState(false);
    const [disabledExemption, setDisableExemption] = useState(true);
    const [exposureZone, setExposureZone] = useState("");
    const [exposureZoneId, setExposureZoneId] = useState("");
    const [vendorEmail, setVendorEmail] = useState(vendor.emailAddress ? vendor.emailAddress : "");
    const isLoading = useSelector(selectLoading);
    const [zoneError, setZoneError] = useState("valid");
    const [taxCodeError, setTaxCodeError] = useState("valid");
    const setting = useSelector(selectSetting, shallowEqual);
    const defaultSigData = useSelector(selectSignatureData, shallowEqual);
    const session = useSelector(selectSession, shallowEqual);
    const [isSignatureError, setIsSignatureError] = useState(false);
    const [staticFile, setStaticFile] = useState(false);
    const coverLetter = useSelector(selectCoverLetterPreview, shallowEqual);
    const documentType = useSelector(selectDocumentTypeInfo, shallowEqual);
    const deliveryType = useSelector(selectDeliveryType);
    const [skipCoverLetter, setSkipCoverLetter] = useState(true);
    const matrixValue = useSelector(selectData, shallowEqual);
    const [noMatrix, setNoMatrix] = useState(false);
    const [clearAll, setClearAll] = useState(false);

    useEffect(() => {
        dispatch(fetchDocumentTypeInfoByName(SalesAndUseTax, true));
    }, [dispatch]); // eslint-disable-line

    const handleNav = () => {
        navigate(`/vendor/${params.id}`);
    };

    const handleReasonChange = detail => {
        if (taxCodeError) {
            setTaxCodeError("valid");
        }
        if (
            (detail.tag === "" ||
                detail.tag === null ||
                detail.tag === "NONE" ||
                detail.tag === "none") &&
            detail.staticFile === false
        ) {
            setNoMatrix(true);
            setTaxCodeError("error");
        } else {
            setNoMatrix(false);
        }
        if (detail.staticFile) {
            setStaticFile(true);
        } else {
            setStaticFile(false);
        }
        setClearAll(false);
        setExemptReasonId(detail.value);
        setExemptReason(detail.label);
    };

    const handleZoneChange = detail => {
        if (zoneError) {
            setZoneError("valid");
        }

        if (taxCodeError) {
            setTaxCodeError("valid");
        }

        setClearAll(false);
        setExposureZone(detail.label);
        setExposureZoneId(detail.value);
        setDisableExemption(false);
    };

    const handleZoneRemove = () => {
        setExposureZone(null);
        setDisableExemption(true);
        setExposureZoneId(null);
        setExemptReason(null);
        setExemptReasonId(null);
        setClearAll(true);
    };

    const handleRegionRemove = () => {
        setExemptReason(null);
        setExemptReasonId(null);
    };

    useEffect(() => {
        dispatch(fetchCustomerAPI(params.id));
    }, [params.id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEmpty(setting)) {
            dispatch(fetchSignatureData(setting.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, setting]);

    useEffect(() => {
        if (!defaultSigData && isEmpty(defaultSigData)) {
            setIsSignatureError(true);
        } else {
            setIsSignatureError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSigData]);

    useEffect(() => {
        setVendorEmail(vendor.emailAddress);
    }, [vendor]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            dispatch(setData([]));
        };
    }, [dispatch]);

    const checkValidation = () => {
        let valid = true;

        if (!validateEmail(vendorEmail) && deliveryType === "E") {
            showToast("warning", `Please provide a valid email address for your vendor.`);
            valid = false;
        }

        if (noMatrix) {
            valid = false;
        }
        if (!exposureZone) {
            setZoneError("error");
            valid = false;
        }
        if (!exemptReason) {
            setTaxCodeError("error");
            valid = false;
        }

        return valid;
    };

    const handleConfirm = () => {
        if (checkValidation()) {
            if (staticFile) {
                dispatch(
                    sendOutgoingStaticDocument({
                        ExposureZoneId: exposureZoneId,
                        CustomerId: vendor.id,
                        CustomerNumber: vendor.customerNumber,
                        TaxCodeId: exemptReasonId,
                        EmailTo: vendorEmail,
                        DeliveryMethod: deliveryType,
                        CoverLetterId: coverLetter.id,
                        CoverLetterOnly: skipCoverLetter === false,
                        AiUserName: session.username,
                    })
                );
            } else {
                enableGenCert(true);
            }
        }
    };

    useEffect(() => {
        if (matrixValue.length && !matrixValue[0].staticFile) {
            handleConfirm();
        }
    }, [matrixValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCheckboxChange = e => {
        if (e.target.checked) {
            setSkipCoverLetter(false);
        } else {
            setSkipCoverLetter(true);
        }
    };

    let vendorRequestDOM = "";
    if (isLoading) {
        vendorRequestDOM = (
            <div>
                <SRow>
                    <SCol>
                        <SLoader loading={isLoading} className="large" aria-live="polite" />
                    </SCol>
                </SRow>
            </div>
        );
    } else if (vendor.isVendor) {
        vendorRequestDOM = (
            <div>
                <div hidden={checkGenCert}>
                    <SRow>
                        <SCol>
                            <h1>
                                Sending certificate to {vendorNumber} - {vendor.name}
                            </h1>
                            <hr className="margin-top-xl margin-bottom-lg" />
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol>
                            <SRow>
                                <SCol span="4">
                                    <SelectCoverLetter
                                        label="Cover letter"
                                        outgoing
                                        labelClass="required"
                                    />
                                </SCol>
                            </SRow>
                            <SRow>
                                <SCol span="4">
                                    <input
                                        id="cl-add"
                                        name="cl-add"
                                        type="checkbox"
                                        aria-label="Add a copy of the cover letter to the attached certificate
                                        form"
                                        checked={skipCoverLetter === false}
                                        onChange={e => {
                                            handleCheckboxChange(e);
                                        }}
                                    />
                                    <label htmlFor="cl-add" className="margin-bottom-md">
                                        Add a copy of the cover letter to the attached certificate
                                        form
                                    </label>
                                </SCol>
                            </SRow>
                            <SRow>
                                <SCol span="4" className="margin-top-sm">
                                    {vendor?.id ? (
                                        <SelectVendorZone
                                            labelClass="required"
                                            onAdd={handleZoneChange}
                                            onRemove={handleZoneRemove}
                                            vendorRegionDisabled={false}
                                            vendorId={vendor?.id}
                                            zoneError={zoneError}
                                            value={exposureZoneId}
                                        />
                                    ) : null}
                                    {zoneError === "error" ? (
                                        <VendorRequestValidationError fieldName="region covered by this certificate" />
                                    ) : null}
                                </SCol>
                            </SRow>
                            <SRow>
                                <SCol span="4" className="margin-top-sm">
                                    <SelectVendorReasons
                                        ref={vendorReasonRef}
                                        onAdd={handleReasonChange}
                                        onRemove={handleRegionRemove}
                                        exposureZoneId={exposureZoneId}
                                        disabled={disabledExemption}
                                        taxCodeError={taxCodeError}
                                        labelClass="required"
                                        value={exemptReasonId}
                                        cleared={clearAll}
                                    />

                                    {(noMatrix || taxCodeError === "error") &&
                                    !disabledExemption ? (
                                        <VendorRequestValidationError
                                            fieldName="reason for exemption"
                                            emptyMatrix={noMatrix}
                                        />
                                    ) : null}
                                </SCol>
                            </SRow>

                            <h3 className="margin-top-md margin-bottom-none">
                                How do you want the completed certificate to be delivered?
                            </h3>
                            <OutgoingDeliveryType />
                            <hr className="margin-top-xl margin-bottom-md" />
                            <button
                                hidden={checkGenCert}
                                id="finish-Vendor-Request-button"
                                disabled={
                                    !dispatch(
                                        isEligibleUser(
                                            featureFlag.vendors.createAndSendCertificates
                                        )
                                    )
                                }
                                onClick={() => handleConfirm()}
                                className="primary large margin-top-lg">
                                {staticFile ? "Finish" : "Next"}
                            </button>
                            <button
                                onClick={() => handleNav()}
                                className="secondary large margin-top-lg">
                                Cancel
                            </button>
                        </SCol>
                    </SRow>
                </div>
                <div hidden={!checkGenCert}>
                    <SRow>
                        <SCol>
                            <div id="gframe" hidden={!checkGenCert}>
                                <GenCert
                                    customer={vendor}
                                    exposureZone={exposureZone}
                                    enableGencert={checkGenCert}
                                    taxCode={exemptReason}
                                    emailAddress={
                                        vendor.emailAddress !== ""
                                            ? vendor.emailAddress
                                            : vendorEmail
                                    }
                                    coverLetterID={coverLetter.id}
                                    docType={documentType[0]?.id}
                                    ecmUser={session.username}
                                    fillOnly
                                    skipSignature
                                    skipCl={skipCoverLetter}
                                    defaultSigData={defaultSigData}
                                />
                            </div>
                        </SCol>
                    </SRow>
                </div>
                <SignatureError
                    userRole={session.role}
                    isSignatureError={isSignatureError}
                    setIsSignatureError={setIsSignatureError}
                    companyName={session.activeCompany.name}
                />
            </div>
        );
    } else {
        vendorRequestDOM = (
            <div>
                <SRow>
                    <SCol>
                        <h3> No vendor record found with code: {vendor.customerNumber} </h3>
                    </SCol>
                </SRow>
            </div>
        );
    }
    return vendorRequestDOM;
}
export default VendorRequest;
