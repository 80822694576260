import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
// import store from "./store";
import { buildApiV3UrlWithQuery } from "../shared/Utils";

const defaultPageData = {
    data: [],
    paginate: {
        pageNumber: 0,
        startIndex: -1,
        endIndex: 0,
        rowsPerPage: 0,
        totalRecords: 0,
    },
};

export const certificateRequestSlice = createSlice({
    name: "certificateRequest",
    initialState: {
        isLoading: false,
        noDataType: "",
        page: defaultPageData,
        sortColumn: ["created", false],
        createSortColumn: ["created", false],
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = defaultPageData;
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setNoDataOnLand: state => {
            state.noDataType = "onLanding";
        },
        setNoDataToNull: state => {
            state.noDataType = "";
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setCreateSortColumn: (state, action) => {
            state.createSortColumn = action.payload;
        },
    },
});

export const {
    setLoading,
    setPage,
    setPageToNull,
    setNoDataType,
    setNoDataOnLand,
    setNoDataToNull,
    setSortColumn,
    setCreateSortColumn,
} = certificateRequestSlice.actions;

export const selectLoading = state => {
    return state.certificateRequest.isLoading;
};

export const selectPage = state => {
    return state.certificateRequest.page;
};

export const selectNoData = state => {
    return state.certificateRequest.noDataType;
};

export const selectSortColumn = state => {
    return state.certificateRequest.sortColumn;
};

export const selectCreateSortColumn = state => {
    return state.certificateRequest.createSortColumn;
};

export const fetchCertificateRequestsAPI =
    (filterString, include, top, skip, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isLoading, sortColumn } = getState().certificateRequest;
        if (isLoading) {
            return;
        }
        dispatch(setLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;

        const apiURI = buildApiV3UrlWithQuery(
            `certificate-requests`,
            filterString,
            include,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (response.data && response.data.count === 0) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull());
        } else {
            dispatch(setNoDataType(""));
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }
        dispatch(setLoading(false));
    };

export default certificateRequestSlice.reducer;
