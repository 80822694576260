import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, SPagination } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import AddEditCertificateLabelsDialog from "./AddEditCertificateLabels";
import DeleteCertificateLabelDialog from "./DeleteCertificateLabel";
import {
    fetchCertificateLabels,
    selectPage,
    selectIsLoading,
    setPageToNull,
    selectSortColumn,
    setSortColumn,
} from "../../../../app/certificateLabelSlice";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";
import featureFlag from "../../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import { selectSession, updateRowCountPreference } from "../../../../app/sessionSlice";
import { getRowsPerPage } from "../../../../shared/Utils";

function CertificateLabelsGrid(props) {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const session = useSelector(selectSession, shallowEqual);
    const page = useSelector(selectPage, shallowEqual);
    const gridData = page.data;
    const pageData = page.paginate;
    const isLoading = useSelector(selectIsLoading);
    const sortColumn = useSelector(selectSortColumn);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [certificateLabel, setCertificateLabel] = useState({});
    const isEligibleUserEditCustomerLabels = !dispatch(
        isEligibleUser(featureFlag.settings.editLabel)
    );
    const isEligibleUserDeleteCustomerLabels = !dispatch(
        isEligibleUser(featureFlag.settings.deleteLabel)
    );

    useEffect(() => {
        dispatch(fetchCertificateLabels(session.rowsPerPage));
        return () => {
            dispatch(setPageToNull([]));
        };
    }, [dispatch]);

    function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        dispatch(fetchCertificateLabels(session.rowsPerPage, null, pageData));
        dispatch(fetchCertificateLabels(session.rowsPerPage, null, pageData));
        setSubmitting(false);
    }

    const columns = [
        gridHeader("Name", "name", value => value, null, "", null, null, sortFunction, "name"),
        gridHeader("Description", "description", value => value),
        gridHeader(
            "",
            "",
            (value, row) => (
                <FeatureToggler category="settings" id="editLabel">
                    <button
                        className="link font-bold"
                        onClick={() => {
                            props.setAddOrEdit("Edit");
                            setCertificateLabel(row);
                            props.setOpenAddUpdateDialog(true);
                        }}
                        disabled={row.systemCode || isEligibleUserEditCustomerLabels}>
                        Edit
                    </button>
                </FeatureToggler>
            ),
            "text-center",
            "text-center"
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <FeatureToggler category="settings" id="deleteLabel">
                    <button
                        className="link font-bold"
                        onClick={() => {
                            setCertificateLabel(row);
                            setIsDeleteDialogOpen(true);
                        }}
                        disabled={row.systemCode || isEligibleUserDeleteCustomerLabels}>
                        Delete
                    </button>
                </FeatureToggler>
            ),

            "text-center",
            "text-center"
        ),
    ];

    const getRowKey = row => {
        return row.id;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && pageData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            dispatch(fetchCertificateLabels(e.detail.rowsPerPage, e.detail.startIndex, e.detail));
            setSubmitting(false);
        }
    };

    const table =
        isLoading || submitting ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <>
                <div className="margin-bottom-xs text-right">
                    {pageData.totalRecords} labels for certificates
                </div>
                <span className="clear" />
                <s-table-container>
                    <DataGrid
                        columns={columns}
                        rows={gridData}
                        loading={isLoading}
                        getKey={getRowKey}
                        inContainer
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            </>
        );

    return !isLoading && pageData.totalRecords === 0 ? (
        <div className="text-center flex dl-flex-fill-height dl-flex-center">
            <h1 className="text-color-no-data">No Labels found</h1>
        </div>
    ) : (
        <React.Fragment>
            {table}
            {pageData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={getRowsPerPage(pageData, session.rowsPerPage)}
                    totalRecords={pageData.totalRecords}
                    startIndex={pageData.startIndex}
                    onS-paginate={handlePaginate}
                />
            ) : null}

            {isDeleteDialogOpen ? (
                <DeleteCertificateLabelDialog
                    isDeleteDialogOpen
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    certificateLabel={certificateLabel}
                />
            ) : null}

            {props.openAddUpdateDialog ? (
                <AddEditCertificateLabelsDialog
                    addOrEdit={props.addOrEdit}
                    certificateLabel={certificateLabel}
                    openAddUpdateDialog={props.openAddUpdateDialog}
                    setOpenAddUpdateDialog={props.setOpenAddUpdateDialog}
                />
            ) : null}
        </React.Fragment>
    );
}

export default CertificateLabelsGrid;
