import React from "react";
import { SInputExtended } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    fetchInvalidReasons,
    setFormState,
    selectFormState,
} from "../../../app/invalidReasonSlice";
import { selectSession } from "../../../app/sessionSlice";

export const searchInputLabel = "Search by name or description";

function InvalidReasonsFilter() {
    const formState = useSelector(selectFormState, shallowEqual);
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const { nameOrDescription } = formState;

    const handleSearch = () => {
        dispatch(fetchInvalidReasons(null, session.rowsPerPage, 0));
    };

    const handleInput = e => {
        dispatch(
            setFormState({
                ...formState,
                [e.detail.inputId]: e.detail.value.trim(),
            })
        );
    };

    const clearSearchBox = async () => {
        dispatch(
            setFormState({
                ...formState,
                nameOrDescription: "",
            })
        );
        handleSearch();
    };

    return (
        <React.Fragment>
            <label htmlFor="nameOrDescription">Search by name or description</label>
            <SInputExtended
                inputId="nameOrDescription"
                aria-label="Search by name or description"
                type="search"
                onS-input={e => handleInput(e)}
                onS-search={e => handleSearch(e)}
                onS-clear={() => clearSearchBox()}
                value={nameOrDescription}
            />
        </React.Fragment>
    );
}
export default InvalidReasonsFilter;
