import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet-async";
import VendorCertificateGrid from "./VendorCertificateGrid";
import NoData from "../NoData";
import {
    fetchVendorCertificatesSearchAPI,
    selectPage,
    selectNoData as selectNoCertificateData,
} from "../../../app/certificateSlice";
import { getPageTitle } from "../../../shared/sessionUtility";
import { selectSession } from "../../../app/sessionSlice";

const VendorCertificates = React.memo(() => {
    const dispatch = useDispatch();
    const pageData = useSelector(selectPage);
    const noCertificateData = useSelector(selectNoCertificateData);
    const session = useSelector(selectSession, shallowEqual);

    useEffect(() => {
        dispatch(fetchVendorCertificatesSearchAPI(false, null, session.rowsPerPage));
    }, [dispatch, session.rowsPerPage]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Vendor certificates"))}</title>
            </Helmet>
            <div className="dl-search">
                <div hidden={!pageData.data.length && noCertificateData === "onLanding"}>
                    <VendorCertificateGrid />
                </div>
                <NoData hidden={pageData.data.length} certificate />
            </div>
        </React.Fragment>
    );
});

export default VendorCertificates;
