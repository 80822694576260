import delay from "delay";
import getConfig from "../config";
import { StatementOfTaxability } from "./constants";

const { appEnv, hostnames, apiVersion3, apiVersion } = getConfig();
const { ecmApiHost } = hostnames;

export const urlToPageMap = {
    "search-certificate": "/search/certificates",
    "search-pending": "/search/pending",
    "search-customer": "/search/customers",
    "search-vendor-certificates": "/search/vendor-certificates",
    review: "/review",
    overview: "/overview",
    retail: "/retail",
    retailSearch: "search",
    retailCustomer: "customer/:id?",
    retailCustomerAndCertificates: "customer-certificates/:id",
    retailAddCertificate: "certificate",
    settings: "/settings",
    customerDetail: "customer/:id?",
};

export function updateSelectedAttribute(array, value) {
    array?.map(x => {
        const arrayValue = x;
        if (arrayValue?.value?.toString()?.toLowerCase() === value?.toString()?.toLowerCase())
            arrayValue.selected = true;
        else arrayValue.selected = false;
        return arrayValue;
    });
}

export const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

export function getFormattedDate(dateString, USFormat) {
    if (dateString === "1970-01-01T00:00:00") {
        return null;
    }

    const date = new Date(dateString);
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : `0${month}`;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;

    if (USFormat) return `${year}-${month}-${day}`;

    return `${month}/${day}/${year}`;
}

export function getURLConstantsKey(pathname) {
    const urlRegex = /\/(customer|certificate|customer-exemptions|campaigns|review)\/(\d+)/;
    return pathname?.match(urlRegex);
}

export function validateEmail(email) {
    const regEx =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    if (email?.match(regEx)) {
        return true;
    }
    return false;
}

export function buildApiV2Point5UrlWithQuery(relativeUrl, filter, include, top, skip, sortColumn) {
    return buildURLWithQuery(
        `//${ecmApiHost}/api/${apiVersion}/${relativeUrl}`,
        filter,
        include,
        top,
        skip,
        sortColumn
    );
}

export function buildApiV2Point5Url(relativeUrl) {
    return buildURL(`//${ecmApiHost}/api/${apiVersion}/${relativeUrl}`);
}

export function buildApiV3UrlWithQuery(relativeUrl, filter, include, top, skip, sortColumn) {
    return buildURLWithQuery(
        `//${ecmApiHost}/api/${apiVersion3}/${relativeUrl}`,
        filter,
        include,
        top,
        skip,
        sortColumn
    );
}

export function buildApiV3Url(relativeUrl) {
    return buildURL(`//${ecmApiHost}/api/${apiVersion3}/${relativeUrl}`);
}

export function buildURL(baseURL) {
    const url = new URL(appEnv === "local" ? `http://${baseURL}` : `https://${baseURL}`);
    return url.href;
}

export function buildURLWithQuery(baseURL, filter, include, top, skip, sortColumn) {
    const url = new URL(appEnv === "local" ? `http://${baseURL}` : `https://${baseURL}`);
    if (filter) {
        url.searchParams.append("$filter", filter);
    }
    if (include && include.length) {
        url.searchParams.append("$include", include.join());
    }
    if (top) {
        url.searchParams.append("$top", top);
    } else if (top !== 0) {
        const rowsPerPage = JSON.parse(localStorage.getItem("rowsPerPage"));
        if (rowsPerPage) {
            url.searchParams.append("$top", rowsPerPage);
        } else {
            url.searchParams.append("$top", 20);
        }
    }
    if (skip) {
        url.searchParams.append("$skip", skip);
    }
    if (baseURL.includes("/v3/") && sortColumn) {
        url.searchParams.append("$orderBy", sortColumn);
    } else if (sortColumn) {
        let [orderByParam, order] = sortColumn;
        order = order ? "asc" : "desc";
        orderByParam = `${orderByParam} ${order}`;
        url.searchParams.append("$orderBy", orderByParam);
    }
    return url.href;
}

export function poll(fn, retries = Infinity, timeoutBetweenAttempts = 1000) {
    return Promise.resolve()
        .then(fn)
        .catch(function retry(err) {
            if (retries - 1 > 0) return delay(timeoutBetweenAttempts).then(fn).catch(retry);
            throw err;
        });
}

export function toTitleCase(str) {
    return str?.replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function fixSearchStringForPostgres(str) {
    let searchString = str?.replace(/&/g, "%26");
    searchString = searchString?.replace(/#/g, "%23");
    return searchString?.replace(/'/g, "''");
}

export function fixSearchStringForCustomers(str) {
    return str.replace(/'/g, "''");
}

export function checkSelectComponentProps(component, props) {
    if (props.onAdd === undefined) {
        throw Error(`You must pass an onAdd function to ${component}`);
    }
    if (props.onRemove === undefined) {
        throw Error(`You must pass an onRemove function to ${component}`);
    }
}

export function sentenceCase(text, ignoreAndReturnEmpty = []) {
    if (text == null) {
        return "";
    }

    if (ignoreAndReturnEmpty?.some(value => text?.includes(value))) {
        return "";
    }

    const textWithCase = text
        ?.toLowerCase()
        ?.split(" ")
        ?.map(word => {
            let char = "";
            if (word?.includes("/")) {
                char = "/";
            }
            if (word?.includes("-")) {
                char = "-";
            }
            return char
                ? word.charAt(0).toUpperCase() +
                      word.slice(1, 1 + word.search(char)) +
                      word.charAt(1 + word.search(char)).toUpperCase() +
                      word.slice(2 + word.search(char))
                : word.charAt(0).toUpperCase() + word.slice(1);
        });
    return textWithCase?.join(" ");
}

export function nextDays(days) {
    const today = new Date();
    return new Date(today.setDate(today.getDate() + days)).toISOString().slice(0, 10);
}
export function getTimeFrame(timeLength) {
    const timeFrames = timeLength?.split(" ");
    const [timeDuration, timeFrame] = timeFrames;
    const isMonth = timeFrame?.match(/months/) != null;
    if (timeLength?.match(/months|days/) == null) {
        return { timeFrame: timeDuration, timeDuration: timeFrame, isMonth };
    }
    // return JSON.stringify({ timeFrame1: timeFrame, duration1: duration, isMonth1: isMonth });
    return { timeFrame, timeDuration, isMonth };
}

export function getDuration(timeLength) {
    const timeFrames = timeLength?.split(" ");
    return timeFrames?.length > 0 ?? timeFrames[0];
}
export function previousDays(days) {
    const today = new Date();
    return new Date(today.setDate(today.getDate() - days)).toISOString().slice(0, 10);
}
export function getdefaultValue(value) {
    switch (typeof value) {
        case "number":
            return 0;
        case "string":
            return "";
        case "boolean":
            return false;
        case "object":
            return [];
        default:
            return null;
    }
}

export function formatTemplate(content) {
    const formattedContent = content?.replaceAll("â", "&apos;");
    return formattedContent;
}

export function matchSubscriptions(subscriptions, productInfo) {
    const match = subscriptions?.filter(sub => productInfo?.includes(sub));
    return match?.length > 0;
}

export function matchRole(allowedRoles, currentRole) {
    return allowedRoles?.some(item => currentRole?.includes(item));
}

export const listDifference = (list1, list2, returnIntersect = false) =>
    list1?.filter(
        (
            set => a =>
                returnIntersect === set?.has(a.id)
        )(new Set(list2?.map(b => b.id)))
    );

export const retainCriticalData = e => {
    let vendorColumnConfigData = null;

    if (localStorage.getItem("vendorColumnConfig")) {
        vendorColumnConfigData = JSON.parse(localStorage.getItem("vendorColumnConfig"));
    }
    localStorage.clear();

    if (vendorColumnConfigData)
        localStorage.setItem("vendorColumnConfig", JSON.stringify(vendorColumnConfigData));

    if (e?.detail?.url) {
        window.location = e.detail.url;
    }
};

export const errorTag = "error";
export const noErrorTag = "no-error";

export function formatDateWithHyphen(date) {
    return new Date(date)
        .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
        })
        .replace(/\//g, "-");
}

export function removeSpaces(str) {
    return str?.replace(/ /g, "");
}

export function lowerCaseAllExceptFirst(str) {
    return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
}

export function convertFieldToString(array, field, nestedObjects) {
    if (!Array.isArray(array)) return [];
    return array.map(element => {
        const copyElement = { ...element };
        if (field && copyElement[field]) copyElement[field] = copyElement[field]?.toString();
        for (let i = 0; i < nestedObjects?.length; i += 1) {
            if (copyElement[nestedObjects[i]]?.id)
                copyElement[nestedObjects[i]].id = copyElement[nestedObjects[i]]?.id?.toString();
        }
        return copyElement;
    });
}

export function hasCsvInjection(input) {
    // Regular expression to match dangerous patterns at the start of a field
    const dangerousPatternRegex = /^(=|\+|-|@)/;

    // Split input into fields based on common delimiters in CSV
    const delimiters = [",", ";", " ", "\t"]; // Include common delimiters
    let insideQuotes = false;
    let startOfField = true;

    // Function to trim and check field for dangerous pattern
    const checkField = field => {
        return dangerousPatternRegex.test(field.trim());
    };

    const fields = [];
    let currentField = "";

    for (let i = 0; i < input?.length; i += 1) {
        const char = input[i];

        if (char === '"') {
            insideQuotes = !insideQuotes;
        }

        if (!insideQuotes && delimiters.includes(char)) {
            fields.push(currentField.trim());
            currentField = "";
            startOfField = true;
        } else {
            currentField += char;
            if (startOfField) {
                startOfField = false;
            }
        }
    }

    // Push the last field into fields array
    fields.push(currentField.trim());

    // Check each field for dangerous patterns
    const suspectedField = fields.find(field => checkField(field));

    // Found a match, indicating potential CSV Injection
    return suspectedField?.length > 0;
}

/**
 * @param {string} input
 * @returns Whether input string is a valid comma separated string
 */
export function isAValidCommaSeparatedString(input) {
    const commaSeparatedValueRegex = /((.+),?)*/;
    if (!input.match(commaSeparatedValueRegex)) {
        return false;
    }
    return true;
}

/**
 * @param {string} input
 * @returns Whether each comma separated value is unique
 */
export function areCommaSeparatedValuesUnique(input) {
    const values = input.split(",");
    const uniqueValues = [...new Set(values)];
    if (values.length !== uniqueValues.length) {
        return false;
    }
    return true;
}

export const fieldValidationTypeCsv = { type: "csvInjection" };
export const fieldValidationTypeRequired = { type: "required" };
export const fieldValidationTypeCommaSeparatedValue = { type: "commaSeparatedValue" };
export const fieldValidationTypeCommaSeparatedUniqueValue = { type: "commaSeparatedUniqueValue" };

export const fieldsValidation = fields => {
    const results = {};
    Object.keys(fields).forEach(fieldName => {
        const field = fields[fieldName];
        const issues = [];
        field.validations.forEach(validation => {
            const { value } = field;
            switch (validation.type) {
                case fieldValidationTypeRequired.type:
                    if (!value.trim()) {
                        issues.push(`${fieldName} is required.`);
                    }
                    break;
                case fieldValidationTypeCsv.type:
                    if (hasCsvInjection(value)) {
                        issues.push(`Remove characters = + - @.`);
                    }
                    break;
                case fieldValidationTypeCommaSeparatedValue.type:
                    if (!isAValidCommaSeparatedString(value)) {
                        issues.push(`Ensure multiple values are comma separated.`);
                    }
                    break;
                case fieldValidationTypeCommaSeparatedUniqueValue.type:
                    if (!areCommaSeparatedValuesUnique(value)) {
                        issues.push(`Please enter unique comma separated values.`);
                    }
                    break;
                default:
                    break;
            }
        });

        if (issues.length > 0) {
            results[fieldName] = { status: "failed", issues };
        } else {
            results[fieldName] = { status: "passed" };
        }
    });
    return results;
};

// Disable any hyperlink programmatically
export function disableLink(id) {
    const element = document.getElementById(id);
    if (!element.hasClickListener) {
        element.addEventListener("click", event => event.preventDefault());
        element.hasClickListener = true;
    }
}
export const getLabelForCustomField = (type, element) => {
    let fieldLabel = element;
    if (type === "boolean") {
        if (element.toUpperCase() === "Y") {
            fieldLabel = "Yes";
        } else {
            fieldLabel = "No";
        }
    }

    return fieldLabel;
};

export const isOneWebDomainRuntime = () => {
    return !!window.AvaAppConfig;
};

export const errorResponse = response => {
    let result = null;
    if (response?.response?.data?.title) {
        result = response?.response?.data;
    } else if (response?.data?.title) {
        result = response?.data;
    }
    return result
        ? {
              code: result?.title,
              message: result?.detail,
              instance: result?.instance,
              status: result?.status,
          }
        : null;
};

// function is used to transform the extemption reason name to 'Statement of taxability' instead of 'taxable'
// for matrix, drop down and grid data
export function transformExemptionData(data, isMatrix, isDropdown, keys = ["name"]) {
    const transformTaxableName = (item, key) => {
        if (item[key]?.toLowerCase() === "taxable") {
            return {
                ...item,
                [key]: StatementOfTaxability,
            };
        }
        return item;
    };
    if (isMatrix === true && isDropdown === false) {
        if (Array.isArray(data)) {
            return data.map(item => {
                // Handle items in the exemption matrix
                return item.exemptReason
                    ? {
                          ...item,
                          exemptReason: transformTaxableName(item.exemptReason, "name"),
                      }
                    : item;
            });
        }
        // Handle the single object case with expectedTaxCodeName and taxCodeName
        return {
            ...data,
            expectedTaxCodeName: transformTaxableName(data, "expectedTaxCodeName")
                .expectedTaxCodeName,
            taxCodeName: transformTaxableName(data, "taxCodeName").taxCodeName,
        };
    }
    if (isMatrix === false && isDropdown === true) {
        return data.map(item => {
            return transformTaxableName(item, "name");
        });
    }

    const deepCopy = obj => JSON.parse(JSON.stringify(obj));

    const updatedData = deepCopy(data);

    const valueArray = Array.isArray(updatedData.data?.value) ? updatedData.data.value : [];

    updatedData.data.value = valueArray.map(record => {
        let updatedRecord = { ...record };
        keys.forEach(key => {
            updatedRecord = transformTaxableName(updatedRecord, key);
        });
        return updatedRecord;
    });

    return updatedData;
}

export const getRowsPerPage = (paginateData, rowsPerPage) => {
    let rowsToReturn = rowsPerPage;
    if (paginateData.totalRecords <= 50 && rowsPerPage === 100) {
        rowsToReturn = 50;
    } else if (paginateData.totalRecords <= 20 && rowsPerPage === 50) {
        rowsToReturn = 20;
    } else if (paginateData.totalRecords <= 10 && rowsPerPage === 20) {
        rowsToReturn = 10;
    }

    return rowsToReturn;
};
