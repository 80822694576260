import axios from "axios";
import store from "../app/store";
import { buildApiV2Point5Url } from "./Utils";

export const logger = async (type, logLevel, params) => {
    let logMsg = "";
    const { session } = store.getState();
    if (!session.isAuthenticated) {
        return;
    }
    if (type === 1) {
        logMsg = `Error page rendered to user ${session.username} with id ${session.userId} for ${session.accountId} amd ${session.activeCompany.id} `;
        logMsg += `Method:${params.method} | Url:${params.url} | Status-Code:${params.status} | StatusText:${params.statusText}`;
    }
    if (type === 2) {
        logMsg = `error:${params.message} |at filename:${params.filename} | lineno:${params.lineno} | colno:${params.colno}`;
    }
    if (type === 3) {
        logMsg = `AUI-UPLOADER: `;
        if (params.error) {
            logMsg += `${params.error} | `;
        }
        logMsg += `accountId:${session.accountId} | companyId:${session.activeCompany.id} | user:${session.userId} ${session.username}`;
    }
    await axios.post(
        buildApiV2Point5Url(`logger`),
        { logLevel, logSource: 0, logMessage: logMsg },
        {
            withCredentials: true,
        }
    );
};

export default logger;
