import React from "react";
import { SInputExtended } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    fetchCustomerLabels,
    setFormState,
    selectFormState,
} from "../../../../app/customerLabelSlice";
import { selectSession } from "../../../../app/sessionSlice";

function CustomerLabelsFilter() {
    const formState = useSelector(selectFormState, shallowEqual);
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const { name } = formState;

    const handleSearchBoxSubmit = () => {
        dispatch(fetchCustomerLabels(session.rowsPerPage));
    };

    const handleInput = e => {
        dispatch(
            setFormState({
                ...formState,
                [e.detail.inputId]: e.detail.value?.trim(),
            })
        );
    };

    const clearSearchBox = async () => {
        dispatch(
            setFormState({
                ...formState,
                name: "",
            })
        );
        handleSearchBoxSubmit();
    };

    return (
        <React.Fragment>
            <label htmlFor="name">Search by name</label>
            <SInputExtended
                inputId="name"
                aria-label="Search by name"
                type="search"
                onS-input={e => handleInput(e)}
                onS-search={e => handleSearchBoxSubmit(e)}
                onS-clear={clearSearchBox}
                value={name}
            />
        </React.Fragment>
    );
}
export default CustomerLabelsFilter;
