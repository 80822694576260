import React, { useCallback } from "react";
import { SInputExtended, SRow, SCol, SIcon } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    setFormState,
    selectFormState,
    selectCampaign,
    fetchCampaignDetailsAPI,
    setIsExportCampaignDialogOpen,
} from "../../../app/requestSlice";
import { selectSession } from "../../../app/sessionSlice";

function BulkRequestFilter() {
    const formState = useSelector(selectFormState, shallowEqual);
    const campaign = useSelector(selectCampaign, shallowEqual);
    const session = useSelector(selectSession, shallowEqual);
    const dispatch = useDispatch();
    const { nameOrCode } = formState;
    const handleSearchBoxSubmit = useCallback(() => {
        dispatch(fetchCampaignDetailsAPI(campaign.id, session.rowsPerPage));
    }, [dispatch, campaign.id, session.rowsPerPage]);

    const handleInput = e => {
        dispatch(
            setFormState({
                ...formState,
                [e.detail.inputId]: e.detail.value.trim(),
            })
        );
    };

    const clearSearchBox = async () => {
        dispatch(
            setFormState({
                ...formState,
                nameOrCode: "",
            })
        );
        handleSearchBoxSubmit();
    };

    const OpenExportCampaignDialog = () => {
        dispatch(setIsExportCampaignDialogOpen(true));
    };

    return (
        <SRow className="align-items-end pad-bottom-sm">
            <SCol span="8">
                <SRow>
                    <SCol span="md-6 xl-6 5" className="pad-bottom-none">
                        <label htmlFor="nameOrCode">Search by customer name or customer code</label>
                        <SInputExtended
                            inputId="nameOrCode"
                            aria-label="Search by customer name or customer code"
                            type="search"
                            onS-input={e => handleInput(e)}
                            onS-search={e => handleSearchBoxSubmit(e)}
                            onS-clear={() => clearSearchBox()}
                            value={nameOrCode}
                        />
                    </SCol>
                </SRow>
            </SCol>
            <SCol span="4">
                <span className="flex justify-content-end">
                    <button
                        type="button"
                        className="primary inline margin-left-sm"
                        onClick={OpenExportCampaignDialog}>
                        <SIcon
                            name="export"
                            className="margin-right-xs"
                            aria-label="Export as a CSV file"
                        />
                        Export as a CSV file
                    </button>
                </span>
            </SCol>
        </SRow>
    );
}
export default BulkRequestFilter;
