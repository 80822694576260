import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import VendorGrid from "./VendorGrid";
import CustomerQuickSearch from "../../sharedComponents/CustomerQuickSearch";

import NoData from "../NoData";
import {
    fetchCustomersAPI,
    selectPage,
    selectNoData as selectNoCustomerData,
    clearCustomerFiltersState,
    setSavedSearchState,
} from "../../../app/customerSlice";
import { selectSession } from "../../../app/sessionSlice";

import { getPageTitle } from "../../../shared/sessionUtility";

const vendors = React.memo(() => {
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const location = useLocation();
    const pageData = useSelector(selectPage);
    const noCustomerData = useSelector(selectNoCustomerData);
    const { searchCriteria } = location?.state ? location.state : [];

    useEffect(() => {
        if (typeof searchCriteria !== "undefined") {
            const searchObj = JSON.parse(searchCriteria);
            dispatch(clearCustomerFiltersState());
            dispatch(setSavedSearchState(searchObj));
        }
        dispatch(fetchCustomersAPI(session.rowsPerPage));
    }, [dispatch, searchCriteria, session.rowsPerPage]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Customers"))}</title>
            </Helmet>
            <div className="dl-search">
                <div className="pad-bottom-sm cx-quick-search">
                    <CustomerQuickSearch />
                </div>
                <h1 className="line-height-md">Vendors</h1>
                <div hidden={!pageData.data.length && noCustomerData === "onLanding"}>
                    <VendorGrid />
                </div>
                <div className="flex dl-flex-fill-height ">
                    <NoData hidden={pageData.data.length} isVendor />
                </div>
            </div>
        </React.Fragment>
    );
});

export default vendors;
