import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { avataxRole, retailTag, subscription, tier } from "../features/navigation/Subscription";
import getConfig from "../config";

const { hostnames, appEnv, apiVersion3 } = getConfig();
const { ecmApiHost, identity, cupHost } = hostnames;

export const sessionSlice = createSlice({
    name: "session",
    initialState: {
        isLoading: false,
        isAuthenticated: false,
        provisionStatus: null,
        isInternalUser: false,
        accountId: null,
        userId: null,
        role: null,
        username: null,
        firstName: null,
        lastName: null,
        email: null,
        companyIds: [],
        activeCompany: {
            id: null,
            name: null,
        },
        subscriptions: [],
        environment: null,
        avaTaxAccountId: null,
        avaTaxUserId: null,
        accessToken: null,
        accessTokenExpiresAt: 0,
        baseSubscription: true,
        isAdminUser: false,
        ecmRole: [],
        isRetailer: false,
        rowsPerPage: 20,
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setData: (state, action) => {
            state.isAuthenticated = action.payload.isAuthenticated;
            state.isInternalUser = action.payload.isInternalUser;
            state.provisionStatus = action.payload.status;
            state.accountId = action.payload.accountId;
            state.userId = action.payload.userId;
            state.role = action.payload.role;
            state.username = action.payload.username;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.companyIds = action.payload.companyIds;
            state.subscriptions = action.payload.subscriptions;
            state.environment = action.payload.environment;
            state.accessToken = action.payload.accessToken;
            state.accessTokenExpiresAt = action.payload.accessTokenExpiresAt;
            state.tier = action.payload.tier;
            state.isAdminUser = action.payload.role?.includes("Admin");
            state.ecmRole = action.payload.ecmRole;
            state.rowsPerPage = action.payload.rowsPerPage;
            state.isRetailer =
                action.payload.role === avataxRole.ECMRoleUser &&
                action.payload.ecmRole?.find(x => x?.startsWith(retailTag));
            if (action.payload.subscriptions) {
                if (
                    action.payload.subscriptions.includes(subscription.ECMPremium) ||
                    action.payload.subscriptions.includes(subscription.ECMStandard) ||
                    action.payload.subscriptions.includes(subscription.ECMPro) ||
                    action.payload.subscriptions.includes(subscription.ECMEssentials) ||
                    action.payload.subscriptions.includes("AvaExemptTier1") ||
                    action.payload.subscriptions.includes("AvaExemptTier2") ||
                    action.payload.subscriptions.includes("AvaExemptTier3") ||
                    action.payload.subscriptions.includes("AvaExemptTier4") ||
                    action.payload.subscriptions.includes(subscription.VEPRO) ||
                    action.payload.subscriptions.includes(subscription.VEPREMIUM) ||
                    action.payload.subscriptions.includes(subscription.AVATAXAP) ||
                    action.payload.tier === tier.ENT
                ) {
                    state.baseSubscription = false;
                }
            }

            if (action.payload.activeCompany) {
                state.activeCompany.id = action.payload.activeCompany.id;
                state.activeCompany.name = action.payload.activeCompany.name;
            }

            if (typeof FS !== "undefined") {
                // eslint-disable-next-line no-undef
                FS.identify(action.payload.userId, {
                    displayName: action.payload.username,
                    email: action.payload.email,
                    // id: avalaraSystemUserId,
                    accountId: action.payload.accountId,
                });
            }
        },

        setSubscriptions: (state, action) => {
            state.subscriptions = action.payload;
        },
    },
});

export const { setLoading, setData, setSubscriptions } = sessionSlice.actions;

export const accessTokenExpiresInMinute = () => (dispatch, getState) => {
    const { session } = getState();
    const accessTokenSampleDate = new Date(1970, 0, 1);
    accessTokenSampleDate.setSeconds(session?.accessTokenExpiresAt);
    const current = Date.now();
    const todayDateFormat = new Date(1970, 0, 1);
    todayDateFormat.setMilliseconds(current);
    const msDiff = accessTokenSampleDate.getTime() - todayDateFormat.getTime();
    return msDiff > 0 ? msDiff - 500 : 0;
};

export const forceRefreshSessionAsync = () => async (dispatch, getState) => {
    const { session } = getState();
    if (session.isLoading) {
        return;
    }
    await axios
        .get(`//${ecmApiHost}/api/v3/session`, {
            withCredentials: true,
        })
        .then(response => {
            if (!response.data.isAuthenticated) {
                window.location = `//${ecmApiHost}/api/v3/auth/login?redirectUrl=${window.location.href}`;
            } else {
                dispatch(setData(response.data));
                dispatch(setLoading(false));
            }
        });

    setTimeout(() => {
        dispatch(forceRefreshSessionAsync());
    }, dispatch(accessTokenExpiresInMinute()));
};

export const getSessionAsync =
    (refresh = false) =>
    async (dispatch, getState) => {
        const { session } = getState();
        const sessionEndpoint = refresh ? "session/refresh-subscriptions" : "session";
        if ((session.isAuthenticated && refresh === false) || session.isLoading) {
            return;
        }

        dispatch(setLoading(true));
        const response = await axios.get(`//${ecmApiHost}/api/v3/${sessionEndpoint}`, {
            withCredentials: true,
        });
        dispatch(setData(response.data));
        if (!response.data.isAuthenticated) {
            window.location = `//${ecmApiHost}/api/v3/auth/login?redirectUrl=${window.location.href}`;
        } else {
            dispatch(setLoading(false));
        }

        setTimeout(() => {
            dispatch(forceRefreshSessionAsync());
        }, dispatch(accessTokenExpiresInMinute()));
    };

export const updateRowCountPreference = preferenceValue => async dispatch => {
    const preferenceValueUpdated = JSON.stringify(preferenceValue);
    await axios.post(
        `//${ecmApiHost}/api/${apiVersion3}/ecm-preferences`,
        {
            preference: "rows-per-page",
            preferenceValue: preferenceValueUpdated,
        },
        { withCredentials: true }
    );
    const response = await axios.get(`//${ecmApiHost}/api/v3/session`, {
        withCredentials: true,
    });
    dispatch(setData(response.data));
};

export const selectSession = state => state.session;

export const selectEcmRole = state => state.session.ecmRole;

export const selectRole = state => state.session.role;

export const getProduct = state => {
    const { session } = state;
    return session.subscriptions;
};

// Data for the Skylab header WebComponent.
// SEE: https://skylabsdk.avalara.io/layout/header
export const selectSkylabHeader = state => {
    const user = JSON.stringify(
        {
            accountId: state.session.accountId,
            givenName: state.session.firstName,
            familyName: state.session.lastName,
            email: state.session.email,
            isLoggedIn: state.session.isAuthenticated,
            isTechSupport: state.session.isInternalUser,
            emitSwitchAccountsNavEvent: !!state.session.isInternalUser,
        },
        null,
        2
    );

    return {
        user,
        productName: "Customer Exemptions",
        env: appEnv,
        appKey: "certcapture",
        homeUrl: window.location.origin,
        loginUrl: `https://${cupHost}`,
        logoutUrl: `//${ecmApiHost}/api/v3/auth/logout`,
        changePasswordUrl: `https://${identity}/changePassword`,
        switchCompanyUrl: `https://${cupHost}/cup/a/${state.session.accountId}/c/${state.session.activeCompany.id}/companies/select`,
    };
};

export default sessionSlice.reducer;
