import React, { useCallback } from "react";
import { SInputExtended } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    fetchRetailLocations,
    selectFormState,
    setFormState,
} from "../../../../app/retailLocationSlice";
import { selectSession } from "../../../../app/sessionSlice";

function RetailLocationsFilter() {
    const formState = useSelector(selectFormState, shallowEqual);
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const { name } = formState;

    const handleSearchBoxSubmit = useCallback(() => {
        dispatch(fetchRetailLocations(session.rowsPerPage));
    }, [dispatch, session.rowsPerPage]);

    const handleInput = e => {
        dispatch(
            setFormState({
                ...formState,
                [e.detail.inputId]: e.detail.value?.trim(),
            })
        );
    };

    const clearSearchBox = async () => {
        dispatch(
            setFormState({
                ...formState,
                name: "",
            })
        );
        handleSearchBoxSubmit();
    };

    return (
        <React.Fragment>
            <label id="lbl-search" htmlFor="name">
                Search by site name or site code
            </label>
            <SInputExtended
                inputId="name"
                id="name"
                type="search"
                aria-labelledby="lbl-search"
                onS-input={e => handleInput(e)}
                onS-search={e => handleSearchBoxSubmit(e)}
                onS-clear={clearSearchBox}
                value={name}
            />
        </React.Fragment>
    );
}
export default RetailLocationsFilter;
