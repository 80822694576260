import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3UrlWithQuery, errorResponse, buildApiV3Url } from "../shared/Utils";
import { documentTypeNames } from "../shared/constants";
import toast from "../hooks/toast";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    formState: {
        nameOrDescription: "",
    },
    sortColumn: ["name", true],
};

export const invalidReasonSlice = createSlice({
    name: "invalidReason",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setSortColumn,
    setIsAddOrEditLoading,
} = invalidReasonSlice.actions;

export const selectIsLoading = state => {
    return state.invalidReason.isLoading;
};
export const selectPage = state => {
    return state.invalidReason.page;
};
export const selectFormState = state => {
    return state.invalidReason.formState;
};
export const selectIsDeleting = state => {
    return state.invalidReason.isDeleting;
};
export const selectSortColumn = state => {
    return state.invalidReason.sortColumn;
};
export const selectIsAddOrEditLoading = state => {
    return state.invalidReason.isAddOrEditLoading;
};

const [showToast] = toast();

export const fetchInvalidReasons =
    (filterString, top = 20, skip, paginateDetails) =>
    async (dispatch, getState) => {
        const { isLoading, formState, sortColumn } = getState().invalidReason;
        const { nameOrDescription } = formState;
        if (isLoading) {
            return;
        }
        dispatch(setIsLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;

        if (nameOrDescription) {
            filterString = `name contains '${formState?.nameOrDescription}' or description contains '${formState?.nameOrDescription}' `;
        }
        const apiURI = buildApiV3UrlWithQuery(
            `invalid-reasons`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, {
            params: {
                documentType: documentTypeNames.salesAndUseTax,
                outgoing: false,
            },
            withCredentials: true,
        });

        if (errorResponse(response)?.code?.toLowerCase() === "objectnotfound") {
            dispatch(setPageToNull());
            dispatch(setIsLoading(false));
            return;
        }

        if (response.data && response.data.count === "0") {
            dispatch(setPageToNull());
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setIsLoading(false));
    };

export const deleteInvalidReasonAsync = (id, invalidReason) => async (dispatch, getState) => {
    const { isDeleting } = getState().invalidReason;

    if (isDeleting) {
        return false;
    }

    dispatch(setIsDeleting(true));
    const response = await axios.delete(buildApiV3Url(`invalid-reasons/${id}`), {
        withCredentials: true,
    });
    if (response?.data?.success) {
        showToast(
            "success",
            `Invalid reason "${invalidReason}" has been deleted successfully`,
            "deleteInvalidReasonToast",
            false
        );
        dispatch(fetchInvalidReasons());
    }
    dispatch(setIsDeleting(false));
    return response;
};
export const AddOrUpdateInvalidReason =
    (addOrEdit, payload, invalidReasonId) => async (dispatch, getState) => {
        const { isAddOrEditLoading } = getState().invalidReason;
        if (isAddOrEditLoading) return false;
        dispatch(setIsAddOrEditLoading(true));
        let success = false;
        if (addOrEdit === "add") {
            await axios
                .post(buildApiV3Url(`invalid-reasons`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (x.name === "AxiosError" && x.response.status === 400) {
                        showToast(
                            "error",
                            `invalid reason already exists.`,
                            "invalid-reason-error-toast",
                            false
                        );
                    }
                    if (x.status === 200) {
                        showToast(
                            "success",
                            `Successfully added the invalid reason.`,
                            "invalid-reason-success-toast",
                            false
                        );
                        success = true;
                        dispatch(fetchInvalidReasons());
                    }
                });
        } else {
            await axios
                .put(buildApiV3Url(`invalid-reasons/${invalidReasonId}`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (x.name === "AxiosError" && x.response.status === 400) {
                        showToast(
                            "error",
                            `invalid reason with same name already exists.`,
                            "invalid-reason-error-toast",
                            false
                        );
                    }
                    if (x.status === 200) {
                        if (x?.data[0]?.message) {
                            showToast(
                                "error",
                                `${x?.data[0]?.message}`,
                                "invalid-reason-error-toast",
                                false
                            );
                        } else {
                            showToast(
                                "success",
                                `Successfully updated the invalid reason.`,
                                "invalid-reason-success-toast",
                                false
                            );
                        }
                        success = true;
                        dispatch(fetchInvalidReasons());
                    }
                });
        }
        dispatch(setIsAddOrEditLoading(false));
        return success;
    };

export default invalidReasonSlice.reducer;
