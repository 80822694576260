import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { SRow, SCol, SStepper, SLoader } from "@avalara/skylab-react";
import classnames from "classnames";
import parse from "html-react-parser";
import RequestGrid from "./RequestGrid";
import CustomizeRequest from "./CustomizeRequest";
import NoData from "../search/NoData";
import { selectSession } from "../../app/sessionSlice";
import {
    requestSearch,
    selectNoData,
    selectCustomerSearchLoading,
    getCustomersWithoutEmail,
    selectRequestFiltersState,
    clearRequestFiltersState,
    clearFormState,
    buildSearchParams,
} from "../../app/requestSlice";
import {
    selectCertificateFiltersState,
    selectFormState as selectCertificateFormState,
    selectExemptionReasons,
} from "../../app/certificateSlice";
import {
    selectCustomerFiltersState,
    selectFormState as selectCustomerFormState,
} from "../../app/customerSlice";
import { fixSearchStringForPostgres, buildApiV3Url } from "../../shared/Utils";
import { getPageTitle, isEligibleUser } from "../../shared/sessionUtility";
import { selectCoverLetterPreview } from "../../app/coverLetterSlice";
import axios from "../../axios";
import toast from "../../hooks/toast";
import adobeAnalytics from "../../shared/adobeAnalytics";
import "./requests.css";
import featureFlag from "../../featureToggler/featureFlag";
import store from "../../app/store";
import getConfig from "../../config";

const { hostnames } = getConfig();
const { docLockerClient } = hostnames;

const requests = React.memo(props => {
    const dispatch = useDispatch();

    const searchType = props.location?.state?.searchType;
    const [step, setStep] = useState(
        searchType === undefined ? "Select recipients" : "Certificate request settings"
    );
    const isEligibleUserCampaignFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.campaign)
    );
    const {
        subscriptions,
        userId,
        accountId,
        isAuthenticated,
        username,
        activeCompany,
        rowsPerPage,
    } = useSelector(selectSession, shallowEqual);
    const certificateFiltersState = useSelector(selectCertificateFiltersState, shallowEqual);
    const certificateFormState = useSelector(selectCertificateFormState, shallowEqual);
    const customerFiltersState = useSelector(selectCustomerFiltersState, shallowEqual);
    const customerFormState = useSelector(selectCustomerFormState, shallowEqual);
    const nextButton = classnames({ primary: true, large: true });
    const sendButton = classnames({ primary: true, large: true, "margin-left-none": true });
    const backButton = classnames({ secondary: true, large: true });
    const cancelButton = classnames({ tertiary: true, large: true });
    const requestFiltersState = useSelector(selectRequestFiltersState);
    const { title } = requestFiltersState;
    const [requestNameError, setRequestNameError] = useState("");
    const navigate = useNavigate();
    const allReasonIds = useSelector(selectExemptionReasons, shallowEqual).map(reason => reason.id);
    const loading = useSelector(selectCustomerSearchLoading);
    const noData = useSelector(selectNoData);
    const [submitting, setSubmitting] = useState(false);
    const coverLetter = useSelector(selectCoverLetterPreview, shallowEqual);
    const regMsg = /\{\{CUSTOM_MESSAGE\}\}/;
    const regName = /\{\{FROM_BUSINESS_NAME\}\}/;
    const [showToast, hideToast] = toast();
    const SelectSavedSearchRef = useRef(null);
    const [deliveryMethod, setDeliveryMethod] = useState("email");
    const [manualReason, setManualReason] = useState(null);

    const [stepItems, setStepItems] = useState([
        {
            label: "Select recipients",
            active: searchType === undefined,
        },
        {
            label: "Certificate request settings",
            active: searchType !== undefined,
        },
        {
            label: "Preview and send",
            active: false,
        },
    ]);
    useEffect(() => {
        if (searchType === undefined) {
            dispatch(requestSearch(rowsPerPage));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const buildParamsFromSearch = (filtersState, searchFormState) => {
        const { nameOrCode, nameOrCodeWhileLoading } = searchFormState;
        const searchVal = nameOrCodeWhileLoading !== "" ? nameOrCodeWhileLoading : nameOrCode;
        return {
            nameOrCode: fixSearchStringForPostgres(searchVal),
            purchaseOrderNumber: filtersState.invoiceNumber,
            isPrimary: filtersState.isCertificateActive,
            isBillTo: filtersState.isBillToRelation,
            isShipTo: filtersState.isShipToRelation,
            certificateImageExists: filtersState.isCertificateImage,
            customerCreatedFrom: filtersState.customerCreatedDateAfter
                ? filtersState.customerCreatedDateAfter
                : null,
            customerCreatedTo: filtersState.customerCreatedDateBefore
                ? filtersState.customerCreatedDateBefore
                : null,
            customerModifiedFrom: filtersState.customerModifiedDateAfter
                ? filtersState.customerModifiedDateAfter
                : null,
            customerModifiedTo: filtersState.customerModifiedDateBefore
                ? filtersState.customerModifiedDateBefore
                : null,
            certificateCreatedFrom: filtersState.certificateCreatedDateAfter
                ? filtersState.certificateCreatedDateAfter
                : null,
            certificateCreatedTo: filtersState.certificateCreatedDateBefore
                ? filtersState.certificateCreatedDateBefore
                : null,
            certificateModifiedFrom: filtersState.certificateModifiedDateAfter
                ? filtersState.certificateModifiedDateAfter
                : null,
            certificateModifiedTo: filtersState.certificateModifiedDateBefore
                ? filtersState.certificateModifiedDateBefore
                : null,
            certificateEffectiveFrom: filtersState.certificateEffectiveDateAfter
                ? filtersState.certificateEffectiveDateAfter
                : null,
            certificateEffectiveTo: filtersState.certificateEffectiveDateBefore
                ? filtersState.certificateEffectiveDateBefore
                : null,
            certificateExpirationFrom: filtersState.certificateExpirationDateAfter
                ? filtersState.certificateExpirationDateAfter
                : null,
            certificateExpirationTo: filtersState.certificateExpirationDateBefore
                ? filtersState.certificateExpirationDateBefore
                : null,
            status: filtersState.certificateStatus,
            exposureZones: filtersState.certificateRegions,
            exemptionReasons: filtersState.exemptReasons,
            customerRegions: filtersState.customerRegions,
            certificateIds: filtersState.certificateIds.length
                ? filtersState.certificateIds
                      .split("\n")
                      .map(value => fixSearchStringForPostgres(value.trim()))
                      .map(Number)
                : [],
            customerCodes: filtersState.customerCodes.length
                ? filtersState.customerCodes
                      .split("\n")
                      .map(value => fixSearchStringForPostgres(value.trim()))
                : [],
        };
    };

    const createRequests = async () => {
        setSubmitting(true);
        const reasonIds =
            requestFiltersState.attachCertificates && requestFiltersState.exemptReasons.length < 1
                ? allReasonIds
                : requestFiltersState.exemptReasons;

        const setCodes = manualReason == null ? reasonIds : manualReason;
        const postData = {
            title: requestFiltersState.title,
            accountName: username,
            accountId: userId,
            searchType: searchType || "customer",
            status: "I",
            expireDaysOut: 30,
            locationId: null,
            documentTypeId: 1,
            customMessage: requestFiltersState.customMessage,
            forMissing: true,
            forExpired: true,
            forInvalid: true,
            forTaxable: true,
            forGood: true,
            excludeSameAs: true,
            expandAddress: true,
            customerSheet: true,
            sendBill: true,
            sendShip: true,
            removeCampaignCustomers: true,
            allowCustomersInMultipleCampaigns: true,
            forExposure: true,
            attachCertificateReasons: setCodes,
            coverLetterId: coverLetter.id,
            deliveryMethod,
        };

        if (searchType === "certificate") {
            postData.certificateSearchParameters = buildParamsFromSearch(
                certificateFiltersState,
                certificateFormState
            );
        } else if (searchType === "customerSearch") {
            postData.customerSearchParameters = buildParamsFromSearch(
                customerFiltersState,
                customerFormState
            );
        } else {
            postData.customerSearchParameters = buildSearchParams(store.getState, 20, 1, false);
        }

        const response = await axios.post(buildApiV3Url(`campaigns`), postData, {
            withCredentials: true,
        });
        setSubmitting(false);
        if (response.status === 200) {
            if (deliveryMethod === "email") {
                showToast(
                    "success",
                    `Your certificate requests have been created. It may take up to a day for your customers to receive requests.`,
                    "request-Toast"
                );
                dispatch(clearRequestFiltersState());
                dispatch(clearFormState());
                navigate("/requests");
            } else if (deliveryMethod === "pdf" || deliveryMethod === "zip") {
                showToast(
                    "success",
                    "Your certificate requests have been created. When they're ready, find them in",
                    "download-success",
                    true,
                    "your downloads",
                    () => {
                        hideToast("download-success");
                        window.open(
                            `https://${docLockerClient}/mylockers/downloads`,
                            "_blank",
                            "noopener,noreferrer"
                        );
                    }
                );
                dispatch(clearRequestFiltersState());
                dispatch(clearFormState());
                navigate("/requests");
            }
        } else {
            showToast("error", "Failed to create certificate requests. Try Again Later.");
        }
    };

    const nextStep = () => {
        let next = "";
        if (step === "Select recipients") {
            next = "Certificate request settings";
            if (searchType === undefined) {
                dispatch(getCustomersWithoutEmail());
            }
            adobeAnalytics(
                { pathname: "/requests/create/2" },
                subscriptions,
                userId,
                accountId,
                isAuthenticated
            );
        } else if (step === "Certificate request settings") {
            if (title === "") {
                setRequestNameError("error");
                return false;
            }
            next = "Preview and send";
            adobeAnalytics(
                { pathname: "/requests/create/3" },
                subscriptions,
                userId,
                accountId,
                isAuthenticated
            );
        } else if (step === "Preview and send") {
            createRequests();
        }

        setStepItems(
            stepItems.map(s => {
                if (s.label === next) {
                    return { label: s.label, active: true };
                }
                return { label: s.label, active: false };
            })
        );
        setStep(next);
        return true;
    };

    const prevStep = () => {
        let prev = "";
        if (step === "Preview and send") {
            prev = "Certificate request settings";
        } else {
            prev = "Select recipients";
        }

        setStepItems(
            stepItems.map(s => {
                if (s.label === prev) {
                    return { label: s.label, active: true };
                }
                return { label: s.label, active: false };
            })
        );
        setStep(prev);
    };

    const handleCancel = () => {
        if (SelectSavedSearchRef?.current) SelectSavedSearchRef?.current?.deselectAll();
        dispatch(clearRequestFiltersState());
        dispatch(clearFormState());
        navigate("/requests");
    };

    const formatCustomEmail = () => {
        let formattedContent;
        try {
            const baseContent = atob(coverLetter?.htmlContent);
            formattedContent = baseContent.replace(regName, activeCompany.name);
            formattedContent = requestFiltersState.customMessage
                ? formattedContent.replace(regMsg, requestFiltersState.customMessage)
                : formattedContent.replace(regMsg, "");
        } catch {
            showToast("error", "Could not display the cover letter, please try again later.");
        }
        return formattedContent;
    };

    const finalStepText = (method, location) => {
        const data = {
            zip: {
                campaignTitle: "Preview and generate download",
                finalizeButton: "Generate download",
            },
            pdf: {
                campaignTitle: "Preview and generate download",
                finalizeButton: "Generate download",
            },
            email: {
                campaignTitle: "Preview and send",
                finalizeButton: "Send",
            },
            default: {
                campaignTitle: "Preview and send",
                finalizeButton: "Send",
            },
        };

        if (!Object.keys(data).includes(method)) {
            return data.default[location];
        }
        return data[method][location];
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Requests"))}</title>
            </Helmet>
            <SRow className="margin-top-md">
                <SCol span="6" offset="3">
                    <SStepper stepItems={stepItems} />
                </SCol>
            </SRow>

            {submitting ? (
                <div className="flex dl-flex-fill-height dl-flex-center">
                    <h3>Loading ...</h3>
                    <SLoader id="page-loader" className="medium" aria-live="polite" loading />
                </div>
            ) : (
                <React.Fragment>
                    <div hidden={step !== "Select recipients"}>
                        <h1 className="line-height-md">Select recipients</h1>
                        <SRow>
                            <SCol span="5">
                                Use the filters to create a list of customers you want to select
                                certificates from
                            </SCol>
                        </SRow>
                        <RequestGrid nextStep={nextStep} savedSearchRef={SelectSavedSearchRef} />
                        <NoData request />
                    </div>
                    <div hidden={step !== "Certificate request settings"}>
                        <CustomizeRequest
                            requestNameError={requestNameError}
                            searchType={searchType}
                            setDelivery={setDeliveryMethod}
                            deliveryType={deliveryMethod}
                            setReason={setManualReason}
                            reason={manualReason}
                        />
                    </div>
                    <div hidden={step !== "Preview and send"}>
                        <h1 className="line-height-md margin-bottom-xl">
                            {finalStepText(deliveryMethod, "campaignTitle")}
                        </h1>
                        <div className="text-gray coverLetterPreview">
                            {"id" in coverLetter ? parse(formatCustomEmail()) : ""}
                        </div>
                    </div>

                    <SRow
                        id="request-footer"
                        className="sticky pin-b white-background pad-bottom-xl">
                        <SCol span="12">
                            <hr className="sticky-hr" />
                            <div className="pad-top-sm">
                                {!(step === "Preview and send") && (
                                    <button
                                        className={nextButton}
                                        onClick={() => nextStep()}
                                        disabled={
                                            loading ||
                                            !isEligibleUserCampaignFlag ||
                                            (noData === "onCustomerSearch" &&
                                                searchType === undefined)
                                        }
                                        type="primary">
                                        Next
                                    </button>
                                )}
                                {!(step !== "Preview and send") && (
                                    <button
                                        className={sendButton}
                                        onClick={() => nextStep()}
                                        disabled={
                                            !isEligibleUserCampaignFlag ||
                                            loading ||
                                            (noData === "onCustomerSearch" &&
                                                searchType === undefined)
                                        }
                                        type="primary">
                                        {finalStepText(deliveryMethod, "finalizeButton")}
                                    </button>
                                )}
                                {!(step === "Select recipients") && (
                                    <button
                                        className={backButton}
                                        disabled={
                                            !isEligibleUserCampaignFlag ||
                                            (searchType !== undefined &&
                                                step === "Certificate request settings")
                                        }
                                        onClick={() => prevStep()}>
                                        Back
                                    </button>
                                )}
                                <button
                                    disabled={!isEligibleUserCampaignFlag}
                                    className={cancelButton}
                                    onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        </SCol>
                    </SRow>
                </React.Fragment>
            )}
        </React.Fragment>
    );
});

export default requests;
