import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import toast from "../hooks/toast";
import {
    buildApiV3UrlWithQuery,
    convertFieldToString,
    errorResponse,
    buildApiV3Url,
} from "../shared/Utils";
import { documentTypeNames, documentTypeOutgoing } from "../shared/constants";

const initialState = {
    isLoadingRegions: false,
    data: [],
    regionData: [],
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 20,
            totalRecords: 0,
        },
    },
    isDeletingRegion: false,
    formState: {
        name: "",
        country: null,
        state: null,
    },
};
export const regionSlice = createSlice({
    name: "region",
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setRegionData: (state, action) => {
            state.regionData = action.payload;
        },
        setIsLoadingRegions: (state, action) => {
            state.isLoadingRegions = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setIsDeletingRegion: (state, action) => {
            state.isDeletingRegion = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
    },
});

export const {
    setData,
    setRegionData,
    setIsLoadingRegions,
    setPage,
    setIsDeletingRegion,
    setPageToNull,
    setFormState,
    clearFormState,
} = regionSlice.actions;

export const selectData = state => {
    return state.region.data;
};

export const selectRegionData = state => {
    return state.region.regionData;
};

export const selectIsLoadingRegions = state => {
    return state.region.isLoadingRegions;
};

export const selectPage = state => {
    return state.region.page;
};

export const selectIsDeletingRegion = state => {
    return state.region.isDeletingRegion;
};

export const selectFormState = state => {
    return state.region.formState;
};

const [showToast] = toast();

export const fetchRegionsWithNexusDetailsAPI =
    (filterString, include, top = 20, skip, sort, paginateDetails) =>
    async (dispatch, getState) => {
        const { isLoadingRegions, formState } = getState().region;
        const { name, country, state } = formState;
        if (isLoadingRegions) {
            return;
        }
        dispatch(setIsLoadingRegions(true));
        sort = "name ASC";
        filterString = `documentType.name="${documentTypeNames.salesAndUseTax}" and documentType.outgoing eq "${documentTypeOutgoing}"`;
        if (name) {
            filterString = `${filterString} and name contains "${name}" `;
        }
        if (country) {
            filterString = filterString
                ? `${filterString} and country.id eq "${country}" `
                : `country.id eq "${country}" `;
        }
        if (state) {
            filterString = filterString
                ? `${filterString} and state.id eq "${state}" `
                : `state.id eq "${state}" `;
        }
        const apiURI = buildApiV3UrlWithQuery(
            `regions-nexus-details`,
            filterString,
            include,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (errorResponse(response)?.code?.toLowerCase() === "objectnotfound") {
            dispatch(setPageToNull());
            dispatch(setIsLoadingRegions(false));
            return;
        }

        if (response.data && response.data.count === "0") {
            dispatch(setPageToNull());
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            const transformedRegions = convertFieldToString(response.data.value, null, [
                "country",
                "state",
            ]);
            dispatch(
                setPage({
                    data: transformedRegions,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setIsLoadingRegions(false));
    };

export const postRegionAPI = postData => async (dispatch, getState) => {
    const { region } = getState();
    let success = false;
    if (region.isLoadingRegions) {
        return [];
    }
    dispatch(setIsLoadingRegions(true));
    await axios.post(buildApiV3Url(`regions`), postData, { withCredentials: true }).then(x => {
        if (x?.name === "AxiosError" && x?.response?.status === 400) {
            showToast(
                "error",
                `Duplicate name found. Region: "${postData.name}" already exists!`,
                "",
                false
            );
            success = false;
        } else if (x?.status === 201) {
            showToast(
                "success",
                `Successfully added the region "${postData.name}".`,
                "add-region-post-success",
                false
            );
            success = true;
        }
    });
    dispatch(setIsLoadingRegions(false));
    return success;
};

export const putRegionAPI = putData => async (dispatch, getState) => {
    const { region } = getState();
    let success = false;
    if (region.isLoadingRegions) {
        return [];
    }
    dispatch(setIsLoadingRegions(true));
    await axios
        .put(buildApiV3Url(`regions/${putData.id}`), putData, {
            withCredentials: true,
        })
        .then(x => {
            if (x?.name === "AxiosError" && x?.response?.status === 400) {
                showToast(
                    "error",
                    `Duplicate name found. Region: "${putData.name}" already exists!`,
                    "",
                    false
                );
                success = false;
            } else if (x?.status === 200) {
                showToast(
                    "success",
                    `Successfully updated region.`,
                    "edit-region-post-success",
                    false
                );
                success = true;
            }
        });
    dispatch(setIsLoadingRegions(false));
    return success;
};

export const deleteRegionAPI = regionData => async dispatch => {
    dispatch(setIsDeletingRegion(true));
    const response = await axios.delete(buildApiV3Url(`regions/${regionData.id}`), {
        withCredentials: true,
    });

    if (response?.data?.success) {
        showToast(
            "success",
            `Region "${regionData.name}" has been deleted successfully`,
            "deleteRegionToast",
            false
        );
    } else if (response?.data?.success === false) {
        showToast(
            "error",
            `"${regionData.name}" is currently being used on one or more certificates. Remove this region from all certificates before deleting it.`,
            "deleteRegionToast",
            false
        );
    }
    dispatch(setIsDeletingRegion(false));
    return response;
};

export default regionSlice.reducer;
