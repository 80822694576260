import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3UrlWithQuery, buildApiV3Url } from "../shared/Utils";
import toast from "../hooks/toast";

export const customerDuplicateSlice = createSlice({
    name: "customerDuplicate",
    initialState: {
        isloading: false,
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 0,
                totalRecords: 0,
            },
        },
        sortColumn: "name DESC",
        duplicateCustomers: [],
        isUnlinkingDuplicateCustomer: false,
        isLinkingDuplicateCustomer: false,
        hasCrossCompanySameAs: false,
        isLoadingClients: false,
        clientData: [],
    },

    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setDuplicateCustomers: (state, action) => {
            state.duplicateCustomers = action.payload;
        },
        setIsUnlinkingDuplicateCustomer: (state, action) => {
            state.isUnlinkingDuplicateCustomer = action.payload;
        },
        setIsLinkingDuplicateCustomer: (state, action) => {
            state.isLinkingDuplicateCustomer = action.payload;
        },
        setHasCrossCompanySameAs: (state, action) => {
            state.hasCrossCompanySameAs = action.payload;
        },
        setLoadingClients: (state, action) => {
            state.isLoadingClients = action.payload;
        },
        setClientData: (state, action) => {
            state.clientData = action.payload;
        },
    },
});

export const {
    setLoading,
    setPage,
    setPageToNull,
    setSortColumn,
    setNoDataType,
    setDuplicateCustomers,
    setIsUnlinkingDuplicateCustomer,
    setIsLinkingDuplicateCustomer,
    setHasCrossCompanySameAs,
    setLoadingClients,
    setClientData,
} = customerDuplicateSlice.actions;

const [showToast] = toast();

export const selectPage = state => {
    return state.customerDuplicate.page;
};

export const selectSortColumn = state => {
    return state.customerDuplicate.sortColumn;
};

export const selectIsLoading = state => {
    return state.customerDuplicate.isloading;
};

export const selectDuplicateCustomers = state => {
    return state.customerDuplicate.duplicateCustomers;
};

export const selectIsUnlinkingDuplicateCustomer = state => {
    return state.customerDuplicate.isUnlinkingDuplicateCustomer;
};

export const selectIsLinkingDuplicateCustomer = state => {
    return state.customerDuplicate.isLinkingDuplicateCustomer;
};

export const selectHasCrossCompanySameAs = state => {
    return state.customerDuplicate.hasCrossCompanySameAs;
};

export const selectIsLoadingClients = state => {
    return state.customerDuplicate.isLoadingClients;
};

export const selectClientData = state => {
    return state.customerDuplicate.clientData;
};

export const fetchCrossCompanySameAsFeature = () => async dispatch => {
    const response = await axios.get(
        buildApiV3Url(`account-features?$filter=name eq 'FEATURE_COMPANY_SAME_AS'`),
        {
            withCredentials: true,
        }
    );

    if (response.data && response.data.value) {
        dispatch(setHasCrossCompanySameAs(response.data.value[0].enabled));
    }
};

export const fetchDuplicateCustomers =
    (customerId, searchVal, top, skip, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isloading, sortColumn } = getState().customerDuplicate;
        if (isloading) {
            return;
        }
        dispatch(setLoading(true));
        const filterString = searchVal
            ? `name contains '${searchVal}' OR customerNumber contains '${searchVal}'`
            : "";

        const apiURI = buildApiV3UrlWithQuery(
            `customers/${customerId}/all-same-as-customers`,
            filterString,
            null,
            top,
            skip,
            sortColumn
        );

        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (!response.data) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull());
            dispatch(setLoading(false));
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
            dispatch(setLoading(false));
        }
    };

export const linkDuplicateCustomerAPI =
    (customerId, sameAsCustomerId, sameAsCompanyId) => async dispatch => {
        dispatch(setIsLinkingDuplicateCustomer(true));

        await axios
            .post(
                buildApiV3Url(
                    `customers/${customerId}/same-as?destinationCustomerId=${sameAsCustomerId}&destinationCompanyId=${sameAsCompanyId}`
                ),
                {
                    destinationCustomerId: sameAsCustomerId,
                },
                {
                    withCredentials: true,
                }
            )
            .catch(error => {
                showToast("error", error.response.data.Message);
            });

        dispatch(fetchDuplicateCustomers(customerId));
        dispatch(setIsLinkingDuplicateCustomer(false));
    };

export const unlinkDuplicateCustomerAPI = (customerId, sameAsCustomerId) => async dispatch => {
    dispatch(setIsUnlinkingDuplicateCustomer(true));

    await axios.delete(buildApiV3Url(`customers/${customerId}/same-as`), {
        data: [
            {
                customerId: sameAsCustomerId,
            },
        ],
        withCredentials: true,
    });

    dispatch(fetchDuplicateCustomers(customerId));

    dispatch(setIsUnlinkingDuplicateCustomer(false));
};

export const fetchSameAsClients = async (dispatch, getState) => {
    const { isLoadingClients } = getState().customerDuplicate;
    if (isLoadingClients) {
        return;
    }
    dispatch(setLoadingClients(true));
    const response = await axios.get(buildApiV3Url(`same-as-clients`), {
        withCredentials: true,
    });

    if (response && response.data) {
        dispatch(setClientData(response.data.value));
    }

    dispatch(setLoadingClients(false));
};

export default customerDuplicateSlice.reducer;
