import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SCol, SRow } from "@avalara/skylab-react";
import { Helmet } from "react-helmet-async";
import PendingSearchGrid from "./PendingSearchGrid";
import NoData from "../NoData";
import {
    fetchPendingCertificatesSearchAPI,
    setSortColumn,
    selectCertificateStatuses,
} from "../../../app/certificateSlice";
import { getPageTitle } from "../../../shared/sessionUtility";
import { selectSession } from "../../../app/sessionSlice";
import CustomerQuickSearch from "../../sharedComponents/CustomerQuickSearch";

const PendingSearch = React.memo(() => {
    const dispatch = useDispatch();
    // Adding dependency of certificates statuses which is used in fetchPendingCertificatesSearchAPI
    const statuses = useSelector(selectCertificateStatuses, shallowEqual);
    const session = useSelector(selectSession, shallowEqual);

    useEffect(() => {
        dispatch(setSortColumn(["id", false]));
        if (statuses.length > 0) dispatch(fetchPendingCertificatesSearchAPI(session.rowsPerPage));
    }, [dispatch, JSON.stringify(statuses)]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Incoming certificates to review"))}</title>
            </Helmet>
            <div className="dl-search">
                <SRow>
                    <SCol span="8">
                        <h1 id="page-header" className="line-height-md margin-bottom-xl">
                            Incoming certificates to review
                        </h1>
                    </SCol>
                    <SCol span="4">
                        <div className="cx-quick-search">
                            <CustomerQuickSearch />
                        </div>
                    </SCol>
                </SRow>
                <PendingSearchGrid />
                <NoData certificate />
            </div>
        </React.Fragment>
    );
});

export default PendingSearch;
